<ng-container *ngLet="orderDetails$ | async as orderDetails">
  <div class="text-center" *ngIf="loading$ | async; else notLoading">
    <span class="countLoader"></span>
  </div>
  <ng-template #notLoading>
    <button id="orderDetails_button_CloseModal" type="button" class="close" data-dismiss="modal" aria-label="Close" tabindex="-1" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="px-2">
      <div class="dashboard-content heading-search">
        <div class="title-of-box-hold">
          <h5 class="strong my-1">Order Details</h5>
        </div>
      </div>
      <div class="m-4 pb-2">
        <div id="orderDetails_div_detailsTable">
          <div class="d-flex justify-content-between">
            <h4 class="strong">Order Number: {{ orderNumber }}</h4>
            <div class="d-flex justify-content-between gx-1">
              <buy-again [orderDetails]="orderDetails" (closeModal)="closeModal()"></buy-again>
              <div ngbDropdown [autoClose]="false" display="dynamic"
                placement="bottom-right" *ngIf="i2pEnabled$ | async">
                <button class="btn btn-primary" type="button" ngbDropdownToggle>Resend Invite to Pay</button>
                <invite-to-pay ngbDropdownMenu class="border-0"
                  [i2p]="i2pDetails$ | async" [orderDetails]="orderDetails"
                  (onSuccess)="onI2pSuccess($event)">
                </invite-to-pay>
              </div>
            </div>
          </div>
          <div class="pb-3">
            <div>Customer Information:</div>
            <div>{{ [orderDetails.orderHistory.name1, orderDetails.orderHistory.name2] | join:" " }}</div>
          </div>
          <div class="pb-2">
            <order-branch [branch]="branch$ | async"></order-branch>
          </div>
          <div class="pb-3">
            <order-customer [orderInfo]="orderDetails.orderHistory"></order-customer>
          </div>
          <order-details-table [orderDetails]="orderDetails"></order-details-table>
          <div *ngIf="orderDetails?.invoiceNumbers?.length">
            <div class="pb-2 bold-font">Invoices:</div>
            <div class="pb-3" *ngIf="orderDetails && orderDetails">
              <order-invoice id="orderDetails_orderInvoice_{{i}}" *ngFor="let invoiceNumber of orderDetails.invoiceNumbers; index as i" [invoiceNumber]="invoiceNumber"></order-invoice>
            </div>
          </div> 
          <ng-container *ngLet="orderDetailsTrackingLinks$ | async as trackingLinks">            
            <div *ngLet="podLinks$ | async as podLinks">            
              <div class="pb-2" *ngIf="trackingLinks?.length || podLinks?.value?.length">
                <div class="bold-font">Proof of Deliveries:</div>
                <div class="h4" id="orderDetails_trackingLink_{{i}}" *ngFor="let trackingLink of trackingLinks; index as i">
                  <a class="hyperlink" [href]="trackingLink.trackingUrl" target="_blank">Tracking #: {{trackingLink.trackingId}}</a>
                </div>
                <span *ngIf="deliveryLoading" id="proof-of-delivery_span_ShowProofOfDeliveryLoader" class="quoteCountLoader"></span>
                <proof-of-delivery id="orderDetails_proofOfDelivery_{{i}}" *ngFor="let link of podLinks?.value; index as i" [documentId]="link.id"></proof-of-delivery>
              </div>
            </div>
          </ng-container>
          <p>Should you have any questions, please call 855-787-4223 and reference confirmation number: {{ orderNumber }}.</p>
          <p>Thank you for using RushCare<sup>&copy;</sup> Parts Connect.</p>
          <p>*This is not an invoice.  The Order details total does not include shipping and handling and/or applicable part fees that may appear on the final invoice.</p>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>
