import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Optional, Output } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Store, select } from '@ngrx/store';
import { LoyaltyDiscountsStore } from "app/_components/loyalty-discounts/loyalty-discounts.store";
import { AppInsightsPriceOverride } from "app/_components/part-details/part-details.component";
import { Branch } from "entities/branch";
import { CurrencyCode } from "entities/currency-code";
import { Discount } from 'entities/discount';
import { Permission } from 'entities/enums';
import { ErrorInformation } from "entities/error-information";
import { ExtendPartRequest } from 'entities/extend-part-request';
import { LoyaltyProgram } from "entities/loyalty-account";
import { Part } from "entities/part";
import { SapCreateExtendPartResults } from "entities/parts/create-extend-part-result";
import { PartPriceOverride } from "entities/parts/part-price-override";
import { VerifiedPrice } from "entities/parts/verified-price";
import { PriceOverrideResult } from "entities/price-override-result";
import { CreatePartModalComponent } from 'modals/create-part-modal/create-part-modal.component';
import { PriceOverrideModalComponent } from "modals/price-override-modal/price-override-modal.component";
import { BehaviorSubject, Observable, Subject, Subscription, merge, of } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { AuthorizationService } from "services/authorization.service";
import { CommonDataService } from "services/common-data.service";
import { calculatePartCouponTotals } from "services/coupon.service";
import { LoaderService } from 'services/loader.service';
import { LoggerService } from "services/logger.service";
import { PartService } from 'services/part.service';
import { ToastService } from "services/toast.service";
import { AppState } from "store/app-state";
import * as BranchSelectors from "store/branch/branch.selectors";
import * as CartActions from 'store/cart/cart.actions';
import * as CartSelectors from 'store/cart/cart.selectors';
import * as ConfigurationSelectors from "store/configuration/configuration.selectors";
import * as CustomerSelectors from "store/customer/customer.selectors";
import { upsertVerifiedPrice } from "store/verified-prices/verified-prices.actions";
import { selectVerifiedPrice } from "store/verified-prices/verified-prices.selectors";


@Component({
  selector: 'pricing',
  templateUrl: 'pricing.component.html'
})

export class PricingComponent implements OnInit, OnDestroy {
  part: PartPriceOverride = null;
  partsData: Part = null;
  private partSubject: Subject<Part> = new BehaviorSubject(null);
  @Input('part') set partInput(value: Part) {
    this.part = value;
    this.partSubject.next(value);
  }
  part$ = this.partSubject.asObservable();
  @Output() partChange: EventEmitter<PartPriceOverride> = new EventEmitter();
  @Input("priceAdjust") set priceAdjustAttribute(value: any) {
    this.priceAdjust = value === '' || value;
  }
  @Input('idPrefix') set _idPrefix(value: string) {
    if (value) {
      this.idPrefix = `${value}_`;
    }
  }
  @Input('idSuffix') set _idSuffix(value: string) {
    if (value) {
      this.idSuffix = `_${value}`;
    }
  }

  @Input() branch: Branch;

  @Output('extend') extendEmitter: EventEmitter<string> = new EventEmitter();

  cartIsLocked$: Observable<boolean> = this.store.select(CartSelectors.selectIsCartLocked);

  idPrefix: string = "";
  idSuffix: string = "";
  priceAdjust$: Observable<boolean>;
  showPriceIq: boolean = false;
  loadingAnyProgramDiscounts$: Observable<boolean> = this.loyaltyDiscountsStore?.loadingDiscounts$
    .pipe(
      map((loadingDiscounts) => Object.values(loadingDiscounts)
        .some((loading) => loading)
      )
    );
  programDiscounts$: Observable<{ [loyaltyProgram in LoyaltyProgram]?: Discount[] }> = this.part$
    .pipe(
      switchMap((part) => this.loyaltyDiscountsStore?.getPartDiscounts(part) ?? of({}))
    );
  hasProgramDiscounts$: Observable<boolean> = this.programDiscounts$
    .pipe(
      map((programDiscounts) => Boolean(Object.keys(programDiscounts).length))
    );
  isMscOrFleetCustomer$: Observable<boolean> = this.store.select(CustomerSelectors.selectIsMscOrFleetCustomer);
  showCreatePartLoader: boolean = false;
  verifiedPrice$: Observable<VerifiedPrice>;

  permission: any = Permission;
  public isInternal$: Observable<boolean> = this.store.select(ConfigurationSelectors.isInternal);
  public createPart$: Observable<boolean> = this.store.select(ConfigurationSelectors.hasPermission(Permission.CreatePart));
  public currencyCode$: Observable<CurrencyCode> = this.store.select(BranchSelectors.selectCurrencyCode);

  private lastModifiedPrice: number;
  private subscription: Subscription;
  private priceAdjust: boolean;

  constructor(
    private store: Store<AppState>,
    @Optional() private loyaltyDiscountsStore: LoyaltyDiscountsStore,
    public commonDataService: CommonDataService,
    public authorizationService: AuthorizationService,
    private loggerService: LoggerService,
    private toastService: ToastService,
    private modalService: NgbModal,
    private partService: PartService,
    private loader: LoaderService,
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnInit() {
   this.lastModifiedPrice = this.part.listPrice;

   this.verifiedPrice$ = this.store
    .pipe(
      select(selectVerifiedPrice, { partNumber: this.part.number }),
      tap((result: VerifiedPrice) => {
        if (result) {
          this.part = {
            ...this.part,
            isPriceVerified: true,
            rebateField: result.rebate,
            verifiedPrice: result.unitPrice
          };
          this.partChange.emit(this.part);
          this.lastModifiedPrice = result.unitPrice;
          this.getLowestPrice();
      }
    }
    ));

    this.verifiedPrice$.subscribe();

    this.priceAdjust$ = this.authorizationService.hasPermissionAsync(Permission.ApplyPriceOverride).pipe(
      map((hasPermission) =>
        this.priceAdjust && hasPermission
      ),
      tap(() => {
        this.getLowestPrice();
      })
    );

    const customerObs = this.store.select(CustomerSelectors.selectedCustomer).pipe(
      tap((customer) => {
        this.showPriceIq = this.commonDataService.User
          && this.commonDataService.User.isInternal
          && customer.pricingProcedure === 'Zilliant';
      }));



    this.subscription = merge(customerObs).subscribe();
  }

  showPriceOverride() {
    const modalRef = this.modalService.open(PriceOverrideModalComponent, { windowClass: "mdModalClass" });
    modalRef.componentInstance.part = this.part;

    return modalRef.result
      .then((overrideResult: PriceOverrideResult & ErrorInformation) => {
        if (overrideResult.ErrorType && overrideResult.ErrorType !== 200) {
          this.toastService.errorMessage('PricingComponent', 'PriceOverride', 'adjustPrice', overrideResult);
        } else {
          const approvalRequired = overrideResult.overridePrice < this.part.overrideFloorActive;
          this.part = {
            ...this.part,
            overridePriceApproval: !approvalRequired
              ? ''
              : overrideResult.branchManagerOverride
                // Approved by manager with override price approval
                ? 'A'
                // Not approved
                // change this to 'B' when you don't need to send it to SAP right away...
                : 'B',
            overridePriceUserId: approvalRequired && overrideResult.branchManagerOverride
              ? this.commonDataService.User.id
              : null,
            adjustedPrice: Number(overrideResult.overridePrice),
            sapCoupons: overrideResult.coupons,
            finalPrice: overrideResult.overridePrice,
            priceOverrideReasonId: overrideResult.reason,
            competitor: overrideResult.competitor
          };
          this.partChange.emit(this.part);

          this.lastModifiedPrice = overrideResult.overridePrice;

          const overrideMetrics = Object.assign(new AppInsightsPriceOverride(), {
            userId: this.commonDataService.User.id,
            branch: this.commonDataService.Branch,
            part: this.part,
            date: new Date()
          });

          this.loggerService.trackMetric('PriceOverride', overrideMetrics);
          this.getLowestPrice();
        }
      }).catch((reason) => { if (reason) { throw reason; } });
  }

  showMscPriceVerifyPopup(part) {
    this.store.dispatch(CartActions.showPriceVerifyModal({items: [part]}));
  }

  // TODO: Is this still in use?
  onMscPriceVerified(mscPriceVerifiedData: any) {
    if (mscPriceVerifiedData) {
      this.part = {
        ...this.part,
        // TODO Not sure if this should be commented or not. should use verified price, but do we need to erase adjusted price?
        // adjustedPrice: 0,
        rebateField: mscPriceVerifiedData.rebate,
        isPriceVerified: mscPriceVerifiedData.isPriceVerified,
        verifiedPrice: mscPriceVerifiedData.unitPrice
      };
      this.partChange.emit(this.part);
      this.lastModifiedPrice = mscPriceVerifiedData.unitPrice;
      if (this.part.isPriceVerified) {
        this.store.dispatch(upsertVerifiedPrice(
        {
            verifiedPrice:
            {
              ...mscPriceVerifiedData
            }
        }));
        this.getLowestPrice();
      }
    }
  }

  getLowestPrice() {
    this.part = {
      ...calculatePartCouponTotals(this.part),
      lowestPrice: this.findLowestPrice(this.part)
    };
    this.partsData = this.part;
    this.partChange.emit(this.part);
  }

  createPart(partNumber: string) {
    const extendPartData: ExtendPartRequest = {
      partNumber: partNumber,
      branchCode: this.branch.code,
      allowReplacedPart: true,
      isSimulated: false
    };
    this.showCreatePartLoader = true;
    this.partService.createExtendedPart(extendPartData)
      .then(responseMsgResult => {
        this.showCreatePartLoader = false;
        if (responseMsgResult.ErrorType != undefined && responseMsgResult.ErrorType != null && responseMsgResult.ErrorType != 200) {
          this.toastService.errorMessage('AppComponent', 'createPart', 'createExtendedPart', responseMsgResult);
        } else {
          this.openPartCreatedResultModal(responseMsgResult);
          this.loggerService.verbose('Create Extended Part: ', responseMsgResult);
          this.loader.loading = false;
        }
      },
        () => { this.loader.loading = false; this.showCreatePartLoader = false; })
      .then(() => {
        this.changeDetectorRef.detectChanges();
      });
  }

  openPartCreatedResultModal(createExtendPartResult: SapCreateExtendPartResults) {
    if (!createExtendPartResult.priceTapeResults || !createExtendPartResult.priceTapeResults.length) {
      this.toastService.showMultilineToast(createExtendPartResult.messages);
    } else if (createExtendPartResult.externalPartNumber && (createExtendPartResult.priceTapeResults.length == 1)) {
      this.toastService.showMultilineToast(createExtendPartResult.messages);
      this.extendEmitter.emit(createExtendPartResult.externalPartNumber);
    } else if (createExtendPartResult.priceTapeResults.length > 1) {
      const modalRef = this.modalService.open(CreatePartModalComponent);
      modalRef.componentInstance.multiplePartsData = createExtendPartResult.priceTapeResults;
      modalRef.componentInstance.branch = this.branch;
      modalRef.result
        .then((createdPartNumber: string) => {
          this.extendEmitter.emit(createdPartNumber);
        })
        .catch(() => { });
    }
  }

  findLowestPrice(part: PartPriceOverride) {
    let _lowestPrice = part.adjustedPrice ? part.adjustedPrice : 0;

    if (part.verifiedPrice && (!_lowestPrice || _lowestPrice > part.verifiedPrice)) {
      _lowestPrice = part.verifiedPrice;
    }
    if (part.listPrice && !_lowestPrice) {
      _lowestPrice = part.listPrice;
    }
    return _lowestPrice;
  }

  ngOnDestroy() {
    if (this.priceAdjust) {
      this.part = {
        ...this.part,
        adustedPrice: undefined,
        finalPrice: undefined,
        priceOverrideReasonId: undefined,
        competitor: undefined
      }
      this.partChange.emit(this.part);
    }
    this.subscription?.unsubscribe();
  }
}
