import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { CartResult, CartResultLineNote, DeliveryType } from 'entities/cart-result';
import { CartSearch, CreateCart } from 'entities/cart-search';
import { AddCartItemRequest } from 'entities/carts/add-cart-item-request';
import { AddNonInventoryBuyoutItemRequest } from 'entities/carts/add-non-inventory-buyout-item-request';
import { AddStoCartItemRequest } from 'entities/carts/add-sto-cart-item-request';
import { BillToInfo } from 'entities/carts/bill-to-info';
import { Cart } from 'entities/carts/cart';
import { CheckoutTransactionData } from 'entities/carts/checkout-transaction-data';
import { RemoveItemsFromCartRequest } from 'entities/carts/remove-items-from-cart-request';
import { SetCartItemPriceRequest } from 'entities/carts/set-cart-item-price-request';
import { SetDeliveryRequest } from 'entities/carts/set-delivery-request';
import { ShippingRateRequest } from 'entities/carts/shipping-rate-request';
import { ShippingRate } from 'entities/carts/shipping-rates';
import { UpdateCartItemFlagRequest } from 'entities/carts/updat-cart-item-flag-request';
import { UpdateCartRequest } from 'entities/carts/update-cart-request';
import { CheckoutSmsOpt } from 'entities/checkout-sms-opt';
import { CreateBillingRequest } from 'entities/create-billing-request';
import { ErrorInformation } from 'entities/error-information';
import { HotFlagResult } from 'entities/hot-flag/hot-flag-result';
import { LoyaltyDiscountSearchCriteria } from 'entities/loyalty-account';
import { ReadyToPickResponse } from 'entities/order-confirmation/ready-to-pick-response';
import { OrderResult } from 'entities/order-result';
import { FavoritePartView } from 'entities/parts/favorite-part-view';
import { PriceExceptionCartRequest, PriceExceptionCartResponse } from 'entities/price-exception';
import { SelectedPartBinLocation } from 'entities/selected-part-bin-location';
import { ShipToCustomer } from 'entities/ship-to-customer';
import { SetTransactionTypeRequest } from 'entities/transaction-type';
import { UpdateCart } from 'entities/update-cart';
import { UpdateCartItemNotesRequest } from 'entities/update-cart-item-notes-request';
import { environment } from 'environments/environment';
import { Observable, Subject, of } from 'rxjs';
import { BaseService } from 'services/base.service';


@Injectable({
  providedIn: "root"
})
export class CartService extends BaseService {
  private readonly cartUrl: string = "api/cart/";
  private readonly cartsUrl: string = "api/carts/";
  private readonly cartItemUrl: string = "api/cartitem/";
  private readonly partsUrl: string = "api/parts/";
  private readonly ordersUrl: string = "api/Orders/";
  private readonly dashboardsUrl: string = "api/dashboards/";
  private readonly paymentUrl: string = "api/payment/";

  private notifyCartChangeEventEmitter: EventEmitter<{ cartId: string }> = new EventEmitter();
  public cartUpdate$ = this.notifyCartChangeEventEmitter.asObservable();
  public featuredPartAdded = new Subject<boolean>();

  constructor(private httpClient: HttpClient) {
    super();
  }

  getHotFlags(): Observable<HotFlagResult[]> {
    return this.httpClient.get<HotFlagResult[]>(`${this.partsUrl}HotFlags`);
  }

  getCarts(searchData: CartSearch): Observable<CartResult> {
    return this.httpClient.put<CartResult>(`${this.cartUrl}GetCartData`, searchData);
  }

  getCartConsolidatedData(cartId: string): Observable<CartResult> {
    const url: string = `${this.cartsUrl}${cartId}`;
    return this.httpClient.get<CartResult>(url);
  }

  setPrice(setCartItemPrice: SetCartItemPriceRequest): Observable<CartResult> {
    const url: string = `${this.cartItemUrl}${setCartItemPrice.cartItemId}/set-price`;
    return this.httpClient.put<CartResult>(url, setCartItemPrice);
  }

  batchAddToCart(cartId: string, addCartItemRequests: AddCartItemRequest[]): Observable<CartResult> {
    return this.httpClient.post<CartResult>(`${this.cartUrl}${cartId}/batch-add`, addCartItemRequests, this.options);
  }

  addToCart(cartId: string, addCartItemRequest: AddCartItemRequest): Observable<CartResult> {
    return this.httpClient.post<CartResult>(`${this.cartUrl}${cartId}/add`, addCartItemRequest);
  }

  addStoToCart(cartId: string, stoCartItem: AddStoCartItemRequest): Observable<CartResult> {
    return this.httpClient.post<CartResult>(`${this.cartUrl}${cartId}/add-sto`, stoCartItem);
  }

  addNonInventoryPartBuyoutToCart(cartId: string, addNonInventoryBuyoutItem: AddNonInventoryBuyoutItemRequest): Observable<CartResult> {
    return this.httpClient.post<CartResult>(`${this.cartUrl}${cartId}/add-non-inventory-part-buyout`, addNonInventoryBuyoutItem);
  }

  addFavoritesToCart(cartId: string, addToCartParameters: FavoritePartView[]): Observable<CartResult> {
    return this.httpClient.post<CartResult>(`${this.cartsUrl}${cartId}/favorites/add`, addToCartParameters);
  }

  /**
   * Gets existing open cart for this user, branch, and customer, or if none exists then creates new cart.
   */
  getOrCreateCart(newCartData: CreateCart): Observable<CartResult> {
    return this.httpClient.post<CartResult>(`${this.cartUrl}`, newCartData);
  }

  getDefaultCartForBranchAndCustomerIfExists(branchCode: string, customerNumber: string): Observable<CartResult> {
    return this.httpClient.get<CartResult>(`${this.cartUrl}?branchCode=${branchCode}&customerNumber=${customerNumber}`);
  }

  createOpenedCart(newCartData: CreateCart): Observable<Cart> {
    return this.httpClient.post<Cart>(`${this.cartUrl}createOpenedCart`, newCartData);
  }

  removeItemsFromCart(itemsToRemoveFromCart: RemoveItemsFromCartRequest): Observable<CartResult> {
    return this.httpClient.put<CartResult>(`${this.cartItemUrl}items/remove`, itemsToRemoveFromCart);
  }

  setDelivery(setDeliveryRequest: SetDeliveryRequest): Observable<CartResult> {
    return this.httpClient.put<any>(`${this.cartUrl}SetDelivery`, setDeliveryRequest, this.options);
  }

  deleteCart(cartId: string): Observable<string> {
    return this.httpClient.delete<string>(`${this.cartUrl}${cartId}`);
  }

  updateBillTo(cartId: string, billTo: BillToInfo): Observable<CartResult> {
    return this.httpClient.put<CartResult>(`${this.cartsUrl}${cartId}/billto`, billTo);
  }

  updateShipTo(cartId: string, shipTo: ShipToCustomer, isEdited: boolean): Observable<ShipToCustomer> {
    return this.httpClient.post<ShipToCustomer>(`${this.cartsUrl}${cartId}/updateShipTo`, { ...shipTo, isEdited });
  }

  getShippingRates(cartId: string): Observable<ShippingRate[]> {
    return this.httpClient.get<ShippingRate[]>(`${this.cartUrl}${cartId}/getShippingRates`);
  }

  getFreight(cartId: string): Observable<CartResult> {
    return this.httpClient.get<CartResult>(`${this.cartUrl}${cartId}/getInboundFreight`);
   }

  getCartShippingRate(cartId: string): Observable<ShippingRate> {
    return this.httpClient.get<ShippingRate>(`${this.cartUrl}${cartId}/getCartShippingRate`);
  }

  updateCartShippingRate(updateShippingRateRequest: ShippingRateRequest): Observable<number> {
    return this.httpClient.put<number>(`${this.cartUrl}updateCartShippingRate`, updateShippingRateRequest);
  }

  updateUnitNumber(unitNumber: string, cartId: string): Observable<CartResult> {
    return this.httpClient.put<CartResult>(`${this.cartsUrl}unitnumber?unitNumber=${unitNumber}&cartId=${cartId}`, {});
  }

  updatePONumber(poNumber: string, cartId: string): Observable<CartResult> {
    poNumber = poNumber.replace(/#/g,'@HASH@');
    return this.httpClient.put<CartResult>(`${this.cartsUrl}ponumber?poNumber=${poNumber}&cartId=${cartId}`, {});
  }

  updateAlternateEmail(alternateEmail: string, cartId: string): Observable<CartResult> {
    return this.httpClient.put<CartResult>(`${this.cartsUrl}alternateEmail?alternateEmail=${alternateEmail}&cartId=${cartId}`, {});
  }

  updateSpecialInstruction(specialInstruction: string, cartId: string): Observable<CartResult> {
    return this.httpClient.put<CartResult>(`${this.cartsUrl}specialInstruction?specialInstruction=${encodeURIComponent(specialInstruction)}&cartId=${cartId}`, {});
  }

  updateSmsOpt(cartId: string, smsOpt: CheckoutSmsOpt): Observable<CartResult> {
    return this.httpClient.put<CartResult>(`${this.cartsUrl}${cartId}/smsopt`, smsOpt);
  }

  updateCartItemFlag(cartItemflagRequest: UpdateCartItemFlagRequest): Observable<CartResult> {
    return this.httpClient.put<CartResult>(`${this.cartItemUrl}flag`, cartItemflagRequest);
  }

  updateCartItem(updateCartItemParameters: UpdateCart): Observable<CartResult> {
    return this.httpClient.put<CartResult>(`${this.cartItemUrl}`, updateCartItemParameters);
  }

  updateCart(updateCartRequest: UpdateCartRequest){
    return this.httpClient.put<CartResult>(`${this.cartsUrl}update`, updateCartRequest, this.options);
  }

  UpdateCartFreightAndDelivery(cartId: string, cartItemId: string, lineId: number, adjustedPrice: number): Observable<CartResult> {
    return this.httpClient.put<CartResult>(`${this.cartUrl}UpdateFreightAndDelivery?cartId=${cartId}&cartItemId=${cartItemId}&lineId=${lineId}&adjustedPrice=${adjustedPrice}`, {}, this.options);
  }

  CheckDefaultBranchValue(branchCode: string): Promise<any> {
    return this.httpClient.put(`${this.cartsUrl}CheckDefaultBranchValue?branchCode=${branchCode}`, {}, this.options)
      .toPromise()
      .catch(this.handleError);
  }

  placeOrder(order: CheckoutTransactionData): Observable<OrderResult> {
    return this.httpClient.post<OrderResult>(`${this.ordersUrl}order`, order);
  }

  readyToPick(request: {
      orderNumber: string;
      selectedBinLocations: SelectedPartBinLocation[];
  }): Observable<ReadyToPickResponse> {

    return this.httpClient.post<ReadyToPickResponse>(`${this.ordersUrl}ReadyToPick`, request, this.options);
  }


  downloadInvoice(orderId: string, coreTags: boolean): Promise<any> {
    const queryParams = [
      `orderId=${orderId}`,
      `includeCoreTags=${coreTags}`
    ];
    const queryString = queryParams.join("&");
    const url: string = `${this.ordersUrl}DownloadGmInvoicePdfFile?${queryString}`;
    return this.httpClient.get(url)
      .toPromise()
      .catch(this.handleError);
  }

  createBilling(createBillingParameters: CreateBillingRequest): Promise<any> {
    return this.httpClient.post(`${this.ordersUrl}CreateBilling`, createBillingParameters)
      .toPromise();
  }

  getCustomerReviewForm(createBillingParameters: CreateBillingRequest): Promise<any> {
    return this.httpClient.post(`${this.ordersUrl}CustomerReview`, createBillingParameters).toPromise();
  }

  submitIBSOverrideCreateBilling(orderId: string, orderNumber: string, authorizationNumber: string): Promise<any> {
    const url: string = `${this.ordersUrl}UpdateAuthorizationAndDownloadInvoice?orderId=${orderId}&orderNumber=${orderNumber}&authorizationNumber=${authorizationNumber}`;
    return this.httpClient.get(url)
      .toPromise()
      .catch(this.handleError);
  }

  getPickTicket(orderId: string): Promise<any> {
    const url: string = `${this.ordersUrl}GetPickTicket?orderId=${orderId}`;
    return this.httpClient.get(url)
      .toPromise()
      .catch(this.handleError);
  }

  downloadSapShortcut(sapOrderNo: string): Promise<any> {
    const url: string = `${this.ordersUrl}GetSapOrderLink?sapOrderNo=${sapOrderNo}`;
    return this.httpClient.get(url)
      .toPromise()
      .catch(this.handleError);
  }

  getOpenCarts(): Observable<Cart[]> {
    const url: string = `${this.cartsUrl}open`;
    return this.httpClient.get<Cart[]>(url);
  }

  declineSuggestedPart(cartId: string): Promise<any> {
    const body = JSON.stringify(cartId);
    return this.httpClient.put(`${this.cartsUrl}DeclineSuggestedPart`, body, this.options)
      .toPromise()
      .catch(this.handleError);
  }

  hideFinalPitch(cartId: string) {
    const body = JSON.stringify(cartId);
    return this.httpClient.put(`${this.cartsUrl}HideFinalPitch`, body, this.options);
  }

  getCloseQuoteReasons(): Promise<any> {
    return this.httpClient.get(`${this.dashboardsUrl}GetCloseQuoteReasonsAsync`, this.options)
      .toPromise()
      .catch(this.handleError);
  }

  getCartItemNotes(cartItemId: string): Observable<CartResultLineNote[]> {
    return this.httpClient.get<CartResultLineNote[]>(`${this.cartItemUrl}${cartItemId}/notes`, this.options);
  }

  updateCartItemNotes(request: UpdateCartItemNotesRequest): Observable<void> {
    return this.httpClient.put<void>(`${this.cartItemUrl}notes`, request);
  }

  createCartFromPriceException(priceExceptionCartRequest: PriceExceptionCartRequest): Observable<PriceExceptionCartResponse & ErrorInformation> {
    return this.httpClient.post<PriceExceptionCartResponse & ErrorInformation>(`${this.cartUrl}createCartFromPriceException`, priceExceptionCartRequest, this.options);
  }

  getTax(cartId: string, loyaltySearchCriteria: LoyaltyDiscountSearchCriteria): Observable<any> {
    // validate cartId
    if (cartId === null || cartId === undefined) {
      return of({taxes: 0});
    } else {
      return this.httpClient.put(`${this.cartUrl}GetTaxes?cartId=${cartId}`, loyaltySearchCriteria, this.options);
    }
  }

  sendConfirmEmail(emailAddress: string, orderId: string, branchName: string ): Promise<any> {
    return this.httpClient.post(`${this.ordersUrl}SendConfirmation?emailAddress=${emailAddress}&orderId=${orderId}&branchName=${branchName}`,   this.options)
    .toPromise()
    .catch(this.handleError);

  }

  setTransactionType(setTransactionTypeRequest : SetTransactionTypeRequest): Observable<any> {
    return this.httpClient.post(`${this.cartUrl}setTransactionType`, setTransactionTypeRequest, this.options);
  }

  getSessionId( customerNumber: string,
                branchCode: string,
                isSred: boolean,
                poNumber: string,
                cartId: string,
                currencyCode: string){
    let referUrl = environment.WEB_URL + '/checkout';
    const originatingSystemName = 'PARTSCONNECT';

    if (isSred === undefined || isSred === null) {
      isSred = false;
    }
    const req ={
      branchCode,
      customerNumber,
      poNumber,
      cartId,
      isSred,
      currencyCode,
      referUrl,
      originatingSystemName
  }
    return this.httpClient.post<{iFrameUrl}>(`${this.paymentUrl}iframe-url`, req)
  }

}

export const nonDeliveryTypes: DeliveryType[] = [
  'Pickup',
  'AdHoc',
  'FcKnown',
  'ShipDirect',
  'SelfPick'
];
