<ng-container *ngLet="user$ | async as user">
  <nav class="navbar navbar-expand-lg navbar-dark bg-secondary">
    <div class="container p-0">
      <a id="headerNav_a_Home" class="navbar-brand" href="javascript:void(0)" (click)="goBackToHomePage(user?.isInternal)">
          <img src="assets/img/PartsConnect_Lockup.png" class="logo-image" title="Home" alt="Home">
      </a>
      <button id="headerNav_button_NavBar" type="button" class="navbar-toggler" aria-expanded="false" aria-controls="navbar"
          (click)="showNavbar = !showNavbar">
          <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" [ngClass]="{ 'show': showNavbar }">
        <ul class="navbar-nav align-items-end align-items-lg-center ms-auto">
          <li class="nav-item" *ngIf="user && user.isInternal">
            <a id="headerNav_a_VIN_PartsSearch" [href]="vinSearchUrl" target="_blank" class="nav-link gx-1">
              <i class="fas fa-serch" aria-hidden="true"></i>
              <span>VIN Parts Search</span>
            </a>
          </li>
          <li class="nav-item dropdown">
            <a id="headerNav_a_PunchoutDropDown" *ngIf="navistarPunchoutEnabled$ | async" class="nav-link dropdown-toggle" href="javascript:void(0)" (click)="showPunchoutMenu = !showPunchoutMenu">OEM Parts Catalogs</a>
            <div class="dropdown-menu" [ngClass]="{ 'show' : showPunchoutMenu }">
              <a id="headerNav_a_PunchoutNavistar" class="dropdown-item" href="javascript:void(0)" (click)="openNavistarPunchoutModal()">Navistar</a>
              <a class="dropdown-item disabled" href="javascript:void(0)" tabindex="-1" aria-disabled="true">PACCAR - Coming Soon</a>
            </div>
          </li>
          <li id="headerNav_li_ProductResearch" class="nav-item" *ngIf="user && user.isInternal; else externalUser">
            <a id="headerNav_a_ProductResearch" class="nav-link" href="javascript:void(0)" [stickyPopover]="prPopoverContent" placement="bottom-left">
              <i class="fas fa-book" aria-hidden="true"></i> Product Research
            </a>
            <ng-template #prPopoverContent>
              <div>
                  <a id="headerNav_a_PaccarEPortal" href="https://eportal.paccar.net/" target="_blank" class="hyperlink">Paccar E-Portal </a><br>
                  <a id="partDetails_a_Parts" href="https://evalue.internationaldelivers.com/partsonline/" target="_blank" class="hyperlink">Navistar Parts </a><br>
                  <a id="headerNav_a_PartsCatalog" href="https://partscatalog.rushtruckcenters.com/" target="_blank" class="hyperlink"><span>All-Makes Parts Catalog</span></a>
              </div>
            </ng-template>
          </li>
          <ng-template #externalUser>
            <li id="headerNav_li_PartsCatalog" class="nav-item">
              <a id="headerNav_a_PartsCatalog" href="https://partscatalog.rushtruckcenters.com/" target="_blank" class="nav-link gx-1">
                <i aria-hidden="true" class="fas fa-book"></i>
                <span>All-Makes Parts Catalog</span>
              </a>
            </li>
          </ng-template>
          <li id="headerNav_li_OrderHistory" *ngIf="!user?.isInternal" class="nav-item">
              <a id="headerNav_a_OrderHistory" class="nav-link" href="javascript:void(0)" [routerLink]="['/orderhistory']">Order History</a>
          </li>
          <li id="headerNav_li_InternalUser" *ngIf="user && !user?.isInternal" class="nav-item">
              <a id="headerNav_a_MyAccount_choices" class="nav-link" [stickyPopover]="externalMyAccountPopoverContent" placement="bottom-left">
                My Account
              </a>
              <ng-template #externalMyAccountPopoverContent >
                <table style="width: 12em">
                    <tr id="headerNav_tr_MyAccount" >
                        <td>
                            <a id="headerNav_a_MyAccount" class="hyperlink" (click)="editProfile()" >
                              <span>Update My Account Details</span>
                            </a>
                        </td>
                    </tr>
                    <tr id="headerNav_tr_ManageCreditCards" >
                        <td>
                            <a id="headerNav_a_ManageCreditCards" class="hyperlink" [href]="[rccUrl]" >
                              <span>Update Credit Card Information</span>
                            </a>
                        </td>
                    </tr>
                </table>
              </ng-template>
          </li>
          <li id="headerNav_li_ShowFavorites" *ngIf="(headerNavShowFavorites$ | async)" class="nav-item">
              <a id="headerNav_a_MyFavorites" class="nav-link" href="javascript:void(0)" [routerLink]="['/favoriteparts']">My Favorites</a>
          </li>
          <ng-container *ngIf="user && user.isInternal">
            <li class="nav-item" *ngIf="(backCounterToolFeatureFlag$ | async) && (backCounter$ | async)">
              <a id="headerNav_a_BackCounter" class="nav-link d-flex align-items-center gx-1" [routerLink]="['/back-counter/dashboard']">
                <i class="fas fa-wrench" aria-hidden="true"></i>
                <span>Back Counter Dashboard</span>
              </a>
            </li>
            <li class="nav-item">
              <a id="headerNav_a_MyDashboard" class="nav-link d-flex align-items-center gx-1" [routerLink]="['/mydashboard']" fragment="open">
                <i class="fas fa-list" aria-hidden="true"></i>
                <span>My Dashboard</span>
              </a>
            </li>
            <li class="nav-item">
              <a id="headerNav_a_OrderHistory" class="nav-link d-flex align-items-center gx-1" [routerLink]="['/mydashboard']" fragment="history">
                <span>Order History</span>
              </a>
            </li>
            <li class="nav-item">
                <a id="headerNav_a_Carts" class="nav-link d-flex align-items-center gx-1" [routerLink]="['/mydashboard']" [queryParams]="{openItemsFilterType: 'cart'}" fragment="open">
                  <span>Carts</span>
                </a>
            </li>
            <li class="nav-item">
                    <a id="headerNav_a_Quotes" *ngLet="(openItemCount$ | async) as openItemCount" class="nav-link d-flex align-items-center gx-1" [routerLink]="['/mydashboard']" fragment="quotes">
                      <span>Quotes</span>
                      <span id="headerNav_span_ShowOpenItemCount" class="quote-count" *ngIf="!loadingOpenItemsCount">{{openItemCount}}</span>
                      <span id="headerNav_span_ShowOpenItemLoader" class="quoteCountLoader" *ngIf="loadingOpenItemsCount"></span>
                    </a>
            </li>
          </ng-container>
          <li class="nav-item">
              <ng-container id="headerNav_container_UserHasReadNotificationAuthorization" *ngIf="authorizationService.hasPermission(permission.ReadNotifications)">
                  <ng-container *ngLet="userNotificationService.unreadUserNotifications$ | async as unreadUserNotifications">
                    <a id="headerNav_a_ReadNotifications" class="nav-link position-relative" href="javascript:void(0)"
                      [ngbPopover]="notificationsPopoverContent" #notificationsPopover="ngbPopover" [popoverTitle]="notificationsPopoverTitle"
                      triggers="manual" placement="bottom" autoClose="outside" (click)="notificationsPopover.toggle()">
                      <i class="fas fa-bell notification-icon"></i>
                      <span id="headerNav_span_ReadNotificationsCount" class="notification-count position-absolute text-white" style="right: 0"
                        *ngIf="!userNotificationService.loadingNotifications">
                        {{unreadUserNotifications?.length}}
                      </span>
                      <span id="headerNav_span_ReadNotificationsLoader" class="notificationCountLoader" *ngIf="userNotificationService.loadingNotifications"></span>
                    </a>
                  <ng-template #notificationsPopoverTitle>
                    <div class="d-flex justify-content-between align-items-center">
                      <h5 class="m-0 text-muted">Notifications ({{unreadUserNotifications?.length}})</h5>
                      <a id="headerNav_a_MarkAllNotificationsAsRead" href="javascript:void(0)" (click)="markAllNotificationsSubject.next()">mark all as read.</a>
                    </div>
                  </ng-template>
                  <ng-template #notificationsPopoverContent>
                    <div class="options-list notifications" *ngLet="userNotificationService.userNotifications$ | async as userNotifications">
                      <div class="notifications-wrapper">
                        <div id="headerNav_div_ReadNotificationsLoadingMessage" class="content" *ngIf="userNotificationService.loadingNotifications && !userNotifications">
                          Loading notifications ...
                        </div>
                        <div id="headerNav_div_NoNewReadNotifications" class="content" *ngIf="!userNotificationService.loadingNotifications && userNotifications && !userNotifications.length">
                          No new notifications.
                        </div>
                        <div id="headerNav_div_ShowReadNotificationsList" *ngIf="!userNotificationService.loadingNotifications && userNotifications && userNotifications.length">
                          <div id="headerNav_div_ShowReadNotification_{{i}}" class="content" *ngFor="let userNotification of userNotifications; let i = index">
                            <a id="headerNav_a_ShowReadNotification_{{i}}" role="button" (click)="userNotificationSubject.next(userNotification); notificationsPopover.close();">
                              <div class="notification-item" [ngClass]="userNotification.isRead == false ? 'unread-notification' : ''">
                                <div>{{userNotification.notification.title}}</div>
                                <div class="text-muted">{{userNotification.notification.createdOn | notificationTime}}</div>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ng-container>
              </ng-container>
          </li>
          <li id="headerNav_li_InternalUserHasSpecialAuthorization" *ngIf="user && user.isInternal && (authorizationService.hasPermission(permission.CreateUsers) || authorizationService.hasPermission(permission.ImportCoupons) || authorizationService.hasPermission(permission.ReadCoupons) || authorizationService.hasPermission(permission.ReadPriceExceptions))" class="nav-item">
              <a id="headerNav_a_InternalUserHasSpecialAuthorization" class="nav-link" [routerLink]="['/users']" [stickyPopover]="cogPopoverContent" placement="bottom-left">
                  <span class="fas fa-cog" style="font-size: 18px;"></span>
              </a>
              <ng-template #cogPopoverContent>
                  <table style="width: 11em">
                      <tr id="headerNav_tr_InternalUserHasCreateUsersAuthorization" *ngIf="authorizationService.hasPermission(permission.CreateUsers)">
                          <td>
                              <a id="headerNav_a_InternalUserHasCreateUsersAuthorization" class="hyperlink" [routerLink]="['/users']">
                                <i style="width: 16px;" class="fas fa-user-plus" aria-hidden="true"></i><span>Admin Dashboard</span>
                              </a>
                          </td>
                      </tr>
                      <tr id="headerNav_tr_InternalUserHasReadPriceExceptionsAuthorization" *ngIf="user && user.isInternal && authorizationService.hasPermission(permission.ReadPriceExceptions)">
                          <td>
                              <a id="headerNav_a_InternalUserHasReadPriceExceptionsAuthorization" class="hyperlink" [routerLink]="['/price-exception/dashboard']">
                                <i style="width: 16px;" class="fas fa-list" aria-hidden="true"></i><span>Price Exception Dashboard</span>
                              </a>
                          </td>
                      </tr>
                  </table>
              </ng-template>
          </li>
          <li id="headerNav_li_UserDropDown" *ngIf="userProfile$ | async as userProfile" class="nav-item dropdown">
            <a id="headerNav_a_UserDropDown" class="nav-link dropdown-toggle" href="javascript:void(0)" (click)="showUserMenu = !showUserMenu">{{ userProfile.unique_name }}</a>
            <div class="dropdown-menu" [ngClass]="{ 'show' : showUserMenu }">
                <a id="headerNav_a_UserSignOut" class="dropdown-item" href="javascript:void(0)" (click)="logout()">Sign Out</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</ng-container>
