export default function ApplyDiscounts(parts: any[], discounts: any): any[] {
  return parts.map(part => {

    if (!discounts) {
      return part;
    }

    const loyaltyDiscount = discounts[part.partNumberBase];
    const discountApplied = part.sapCoupons?.some((coupon: any) => coupon.code == loyaltyDiscount?.code);

    if (loyaltyDiscount && !discountApplied) {
      const quantity = part.quantity;
      const coupons = [];

      for (let i = 0; i < quantity; i++) {
        const loyaltyProgram = loyaltyDiscount.loyaltyProgram;
        coupons.push({
          amount: loyaltyDiscount.amount,
          description: loyaltyDiscount.code + " " + loyaltyProgram.charAt(0).toUpperCase() + loyaltyProgram.slice(1),
          type: 'part',
          code: loyaltyDiscount.code
        });
      }

      const combinedCoupons = [
        ...part.sapCoupons,
        ...coupons
      ];

      const distinctCombinedCoupons = combinedCoupons
        .filter((n, i) => combinedCoupons.map(x => x.description).indexOf(n.description) == i);


      return {
        ...part,
        sapCoupons: distinctCombinedCoupons,
        couponTotal: part.sapCoupons.map(coupon => coupon.amount).reduce((prev, curr) => {
            return prev + curr;
          }, 0
        )
      };
    }
    return part;
  })
}


