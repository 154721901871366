import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { CheckoutShipToFormConfig } from "app/_components/checkout-ship-to/checkout-ship-to.models";
import { CheckoutValidationMessages } from "app/_components/checkout-validation/checkout-validation.messages";
import { CheckoutFormGroup } from "app/_components/checkout/checkout.component";
import { mapErrorKeysToMessages } from "helpers/map-error-messages";
import { isNullOrEmpty } from "validators/null-or-empty.validator";

export const shipToValidator = (
    regexPhonePattern: RegExp,
    regexUSAPostalPattern: RegExp,
    regexCanadaPostalPattern: RegExp
): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
        const checkoutFormGroup = control.root as FormGroup<CheckoutFormGroup>;
        const selectedDelivery = checkoutFormGroup?.controls?.shippingAndDelivery?.controls?.deliveryType?.value;
        const shipToValue = checkoutFormGroup?.controls?.shippingAndDelivery?.controls?.shipTo?.value;
        const addressRequired = ["shipto", "delivery", "shipdirect"].includes(selectedDelivery);
        const shipToAddressNumber = shipToValue?.addressNumber;
        const isCashCustomer = shipToAddressNumber === '0000200000';
        const isCashCustomerOrAddressIsEmpty = isCashCustomer || isNullOrEmpty(shipToValue?.streetAddress);

        if (!shipToValue) {
            return {
                "shipto.required": CheckoutValidationMessages["shipto.required"]
            }
        }

        const requiredFieldsErrors = {
            "shipto.customerName.required": isCashCustomerOrAddressIsEmpty && isNullOrEmpty(shipToValue?.customerName),
            "shipto.streetAddress.required": addressRequired && isNullOrEmpty(shipToValue?.streetAddress),
            "shipto.city.required": addressRequired && isNullOrEmpty(shipToValue?.city),
            "shipto.state.required": addressRequired && isNullOrEmpty(shipToValue?.state),
            "shipto.postalCode.required": isNullOrEmpty(shipToValue?.postalCode),
            "shipto.phoneNumber.required": isCashCustomerOrAddressIsEmpty && isNullOrEmpty(shipToValue?.phoneNumber)
        };

        const patternErrors = {
            "shipto.customerName.maxlength": shipToValue?.customerName?.length > CheckoutShipToFormConfig.customerName.maxLength,
            "shipto.streetAddress.maxlength": shipToValue?.streetAddress?.length > CheckoutShipToFormConfig.streetAddress.maxLength,
            "shipto.phoneNumber.pattern": (!isNullOrEmpty(shipToValue?.phoneNumber) && !new RegExp(regexPhonePattern).test(shipToValue?.phoneNumber)),
            "shipto.postalCode.pattern": (!isNullOrEmpty(shipToValue?.postalCode) && (shipToValue?.isCanadaUser ?
                !regexCanadaPostalPattern.test(shipToValue?.postalCode) :
                !regexUSAPostalPattern.test(shipToValue?.postalCode))),
        }

        const errors = { ...requiredFieldsErrors, ...patternErrors };
        if (Object.keys(errors).length > 0) {
            return mapErrorKeysToMessages(errors);
        }
        return null;
    };
};