import { Component, ViewChild, ElementRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { Cart } from 'entities/cart-search';
import { Permission } from 'entities/enums';
import { PartSearch } from 'entities/part-search';
import { Part } from 'entities/part';
import { ToastService } from 'services/toast.service';
import { LoggerService, SourceLocationType } from 'services/logger.service';
import { AuthorizationService } from 'services/authorization.service';
import { PartService } from 'services/part.service';
import { VendorService } from 'services/vendor.service';
import { AddNonInventoryBuyoutItemRequest } from 'entities/carts/add-non-inventory-buyout-item-request';
import { AppState } from 'store/app-state';
import * as CartActions from 'store/cart/cart.actions';
import { selectCurrencySymbol } from 'store/branch/branch.selectors';

@Component({
  selector: 'unknown-part-buyout-modal',
  templateUrl: './unknown-part-buyout-modal.component.html'
})
export class UnknownPartBuyoutModalComponent {
  @ViewChild('UnknownPartBuyoutModal_PartNumberOnlyText') partNumberRef: ElementRef;
  subject: Subject<string> = new Subject();
  sub1: any;
  partsbuyoutQuantity: any = 1;
  partNo: string = 'PARTSBUYOUTTX';
  partNoOnly: string;
  description: string;
  manufacturer: string;
  price: string = '1.50';
  customerCorePrice: string = '1.50';
  cartsData: any;
  addToCartItem: any;
  selectedPartBuyoutCoreOption: string = 'NOCORE';
  vendor: any = null;
  buyoutAmount: string = '1.00';
  vendorCorePurchasePrice: string = '1.00';
  permission: any = Permission;
  isCorePart: boolean = false;
  isBuyoutGreaterthanSales: boolean = false;
  isPartNumberValid: boolean = true;
  isPartNumberReturned: boolean = false;
  isValid: boolean = true;
  chkwarningmodel: boolean = false;
  showGettingPartsLoader: boolean = false;
  knownParts: Part[] = null;
  source = SourceLocationType.PartBuyoutModalComponentAddToCartUnknownPartBuyout;

  addToCartParameters: Cart = new Cart();

  userId: string;
  branchCode: string;
  customerNumber: string;
  customerName: string;
  cartId: string;

  public currencySymbol$ = this.store.select(selectCurrencySymbol);

  constructor(
    private store: Store<AppState>,
    private toastService: ToastService,
    private partService: PartService,
    private vendorService: VendorService,
    private loggerService: LoggerService,
    public authorizationService: AuthorizationService,
    private activeModal: NgbActiveModal
  ) {
    this.toastService.hideToast();
  }

  onChangePartBuyout(newValue) {
    this.loggerService.verbose('Partbuyout modal component on change : ', newValue);
    this.selectedPartBuyoutCoreOption = newValue;
    // if (newValue != null && newValue != '' && newValue != undefined) {
    //   this.isCorePart = true;
    // } else {
    //   this.isCorePart = false;
    // }

    this.isCorePart = this.selectedPartBuyoutCoreOption !== 'NOCORE';

  }

  closeModal() {
    this.activeModal.dismiss();
  }

  addToCart(partNo = 'PARTSBUYOUTTX', partNoOnly, description, manufacturer, price, partsbuyoutQuantity, partBuyoutCoreOption) {
    this.addToCartParameters = Object.assign(new Cart(), {
      userId: this.userId,
      branchCode: this.branchCode,
      customerNumber: this.customerNumber,
      partNumber: partNo,
      description,
      yourPrice: price,
      quantity: partsbuyoutQuantity == '0' ? '1' : partsbuyoutQuantity,

      coreOption: partBuyoutCoreOption,
      coreprice: this.selectedPartBuyoutCoreOption !== 'NOCORE' ? this.customerCorePrice : 0,
      corePartNumber: null,
      partId: null,
      cost: this.buyoutAmount,
      listPrice: price,
      priceOverrideReasonId: null,
      PartNumberBase: partNoOnly,
      isBuyout: true,
      cateogory: 'P0101',
      manufacturer,
      partBuyoutCoreOption,
      vendorCode: this.vendor.number,
      corePurchasePrice: this.selectedPartBuyoutCoreOption !== 'NOCORE' ? this.vendorCorePurchasePrice : 0,
      cartId: this.cartId
    });

    const partNumber: string = (partNoOnly != null ? (partNoOnly + ',') : '') + (description != null ? (description + ',') : '') + (manufacturer != null ? manufacturer : '');
    const successMessage: string = `${partNo} ${partNoOnly} ${description != null ? description : ''} with ${partsbuyoutQuantity} quantity added successfully.`;

    const addNonInventoryPartBuyout: AddNonInventoryBuyoutItemRequest = {
      addCartItemRequest: {
        binLocation: '',
        partId: null,
        partNumber: 'PARTSBUYOUTTX',
        coreOption: this.selectedPartBuyoutCoreOption,
        quantity: partsbuyoutQuantity === '0' ? 1 : +partsbuyoutQuantity,
        adjustedPrice: 0,
        description: '',
        trackingParameters: JSON.stringify(this.addToCartParameters),
        sapCoupons: []
      },
      coreCustomerPrice: this.selectedPartBuyoutCoreOption !== 'NOCORE' ? +this.customerCorePrice : 0,
      corePurchasePrice: this.selectedPartBuyoutCoreOption !== 'NOCORE' ? +this.vendorCorePurchasePrice : 0,
      customerPrice: Number(price),
      description: description,
      manufacturer: manufacturer,
      purchasePrice: Number(this.buyoutAmount),
      vendorCode: this.vendor.number,
      vendorName: this.vendor.name,
      category: 'P0101',
      partNumberBase: partNoOnly
    };

    this.store.dispatch(CartActions.addItemToCartFromPartsBuyout({
      addNonInventoryPartBuyout,
      modal: this.activeModal,
      partNumber,
      successMessage,
      buyoutAmount: this.buyoutAmount,
      price: this.price,
      vendor: this.vendor,
      quantity: this.addToCartParameters.quantity
    }));
  }

  // Vendor Start
  removeFavouriteVendors(selectedVendor) {
    this.vendorService.deleteFavouriteVendors(selectedVendor.number)
      .then((favVendors) => {
        if (favVendors?.ErrorType && favVendors.ErrorType != 200) {
          this.toastService.errorMessage('UnknownPartBuyoutModalComponent', 'removeFavouriteVendors', 'deleteFavouriteVendors', favVendors);
        } else {
          this.vendor.isFavorite = false;
          this.vendorService.notifyFavouriteVendorChange(selectedVendor);
        }
      },
        (error) => { });
  }

  addToFavouriteVendors(selectedVendor) {
    this.vendorService.createFavouriteVendors({vendorNumber: selectedVendor.number, branchCode: this.branchCode})
      .then((favVendors) => {
        if (favVendors?.ErrorType && favVendors.ErrorType != 200) {
          this.toastService.errorMessage('UnknownPartBuyoutModalComponent', 'addToFavouriteVendors', 'createFavouriteVendors', favVendors);
        } else {
          this.vendor.isFavorite = true;
          this.vendorService.notifyFavouriteVendorChange(selectedVendor);
        }
      },
        (error) => { });
  }

  useDifferentVendor() {
    this.vendor = null;
  }

  changeQuantity(qty: number) {
    this.partsbuyoutQuantity = qty;
  }

  priceKeyPress(e: any) {
    let priceVal: any = e.currentTarget.value;

    // allows 0-9, backspace, and decimal
    if (((e.which < 48 || e.which > 57) && e.which != 8 && e.which != 46)) {
      return false;
    }

    // checks to make sure only 1 decimal is allowed
    if (e.which == 46) {
      if (priceVal.indexOf('.') != -1) {
        return false;
      }
    }

    // if (/^\d*(\.\d\d)$/.test(priceVal)) {
    //    return false;
    // }
  }

  getPartData(partNumber: any) {
    this.subject.subscribe(value => partNumber);
    if (partNumber === '') {
      this.loggerService.verbose('PartNumber field is empty');
    } else {
      this.showGettingPartsLoader = true;
      const searchCriteria: PartSearch = {
        ...new PartSearch(),
        partSearchTerm: partNumber,
        branchCode: this.branchCode,
        customerNumber: this.customerNumber,
        isCountCheck: false
      };
      this.sub1 = this.partService.searchParts(searchCriteria)
        .pipe(first())
        .subscribe(response => {
          if (response.ErrorType != undefined && response.ErrorType != null && response.ErrorType != 200) {
            this.showGettingPartsLoader = false;
            this.toastService.errorMessage('UnknownPartBuyoutModalComponent', 'getPartData', 'getParts', response);
          } else {
            this.showGettingPartsLoader = false;
            this.loggerService.verbose('Parts component parts data : ', response);
            this.loggerService.verbose(response.totalParts);
            if (response.totalParts === 0) {
              this.isPartNumberValid = false;
              this.isPartNumberReturned = false;
            } else if (response.totalParts >= 1) {
              this.knownParts = response.parts;
              this.loggerService.verbose(this.knownParts);
              this.isPartNumberValid = false;
              this.isPartNumberReturned = true;
            } else {
              this.isPartNumberValid = true;
              this.isPartNumberReturned = false;
            }
            this.loggerService.verbose(this.isPartNumberValid + '========' + this.isPartNumberReturned);
          }
        },
          (error) => {
            this.showGettingPartsLoader = false;
            this.loggerService.verbose('"part-buyout-modal" getPartData err');
          });
    }
  }

  buyoutPriceOnPaste(e: any) {
    let content = e.clipboardData.getData('text/plain');
    this.buyoutCorrectPrice(content);
    return false;
  }

  buyoutCorrectPrice(textPrice: string) {
    let match = textPrice.match(/\.?[0-9]\d*(\.\d{1,2})?/g);
    let newBuyoutAmount = '1.00';
    if (match && match.length) {
      const matchFloat = parseFloat(match[0]);
      newBuyoutAmount = matchFloat > 0 ? matchFloat.toFixed(2) : '1.00';
      const buyoutAmountFloat = parseFloat(newBuyoutAmount);
      this.price = buyoutAmountFloat > 0 ? (buyoutAmountFloat * 1.5).toFixed(2) : '1.50';
      this.isBuyoutGreaterthanSales = parseFloat(this.price) < buyoutAmountFloat;
      this.isValid = !this.isBuyoutGreaterthanSales;
    }

    // Forcing update of ngModel input binding
    this.buyoutAmount = '';
    setTimeout(() => this.buyoutAmount = newBuyoutAmount);
  }

  priceOnPaste(e: any) {
    let content = e.clipboardData.getData('text/plain');
    this.correctPrice(content);
    return false;
  }

  correctPrice(textPrice: string) {
    let match = textPrice.match(/\.?[0-9]\d*(\.\d{1,2})?/g);
    let newPrice = '1.50';
    if (match && match.length) {
      const matchFloat = parseFloat(match[0]);
      newPrice = matchFloat > 0 ? matchFloat.toFixed(2) : '1.50';
      this.isBuyoutGreaterthanSales = parseFloat(newPrice) < parseFloat(this.buyoutAmount);
      this.isValid = !this.isBuyoutGreaterthanSales;
    }

    // Forcing update of ngModel input binding
    this.price = '';
    setTimeout(() => this.price = newPrice);
  }

  vendorPriceOnPaste(e: any) {
    let content = e.clipboardData.getData('text/plain');
    this.vendorCorrectPrice(content);
    return false;
  }

  vendorCorrectPrice(textPrice: string) {
    let match = textPrice.match(/\.?[0-9]\d*(\.\d{1,2})?/g);
    let newVendorCorePurchasePrice = '1.00';
    if (match && match.length) {
      const matchFloat = parseFloat(match[0]);
      newVendorCorePurchasePrice = matchFloat > 0 ? matchFloat.toFixed(2) : '1.00';
      const vendorCorePurchasePriceFloat = parseFloat(newVendorCorePurchasePrice);
      this.customerCorePrice = vendorCorePurchasePriceFloat > 0 ? (vendorCorePurchasePriceFloat * 1.5).toFixed(2) : '1.50';
    }

    // Forcing update of ngModel input binding
    this.vendorCorePurchasePrice = '';
    setTimeout(() => this.vendorCorePurchasePrice = newVendorCorePurchasePrice);
  }

  customerPriceOnPaste(e: any) {
    let content = e.clipboardData.getData('text/plain');
    this.customerCorrectPrice(content);
    return false;
  }

  customerCorrectPrice(textPrice: string) {
    let match = textPrice.match(/\.?[0-9]\d*(\.\d{1,2})?/g);
    let newCustomerCorePrice = '1.50';
    if (match && match.length) {
      const matchFloat = parseFloat(match[0]);
      newCustomerCorePrice = matchFloat > 0 ? matchFloat.toFixed(2) : '1.50';
    }

    // Forcing update of ngModel input binding
    this.customerCorePrice = '';
    setTimeout(() => this.customerCorePrice = newCustomerCorePrice);
  }

  chkWarningclick(e) {
    if (e.target.checked) {
      this.isValid = true;
    } else {
      this.isValid = false;
    }
  }

  onVendorSelect(vendor) {
    this.vendor = vendor;
    this.partNumberRef?.nativeElement?.focus();
  }
}
