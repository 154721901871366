import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Branch } from "entities/branch";
import { OnChange, OnTouched } from 'entities/control-value-accessor-funcs';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { AppState } from 'store/app-state';
import * as BranchSelectors from 'store/branch/branch.selectors';

@Component({
  selector: 'branch',
  templateUrl: './branch.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BranchComponent),
      multi: true
    }
  ]
})
export class BranchComponent implements ControlValueAccessor {
  branchDropdownOption: number = 0;
  @Input() allowParentToSetBranchValue?: boolean = false;
  public disabled: boolean;
  branchValue: Branch;
  public branchValue$: Observable<Branch> = this.store.select(BranchSelectors.selectedBranch).pipe(
    filter( (branch) => branch !== undefined ),
    tap((selectedBranch: Branch) => {
      if (this.allowParentToSetBranchValue) {
        this.branchValue = selectedBranch;
      }
    })
  );
  public branches$: Observable<Branch[]> = this.store.select(BranchSelectors.selectBranchesForOrdering);
  public readonly branchSearch: (text$: Observable<string>) => Observable<Branch[]>;
  public isSearching: boolean;

  private onChange: OnChange<Branch> = (newValue: Branch) => { };
  private onTouched: OnTouched = () => {};

  constructor(
    private store: Store<AppState>
  ) {

    this.branchSearch = (text$: Observable<string>) => text$
      .pipe(
        debounceTime(250),
        distinctUntilChanged(),
        tap(() => {
          this.isSearching = true;
        }),
        withLatestFrom(this.branches$),
        map(([text, branches]: [string, Branch[]]) =>
          branches.filter((branch: Branch) =>
            !branch.code.startsWith('900') &&
            !branch.displayName.includes('RTL') &&
            (
                branch.code.startsWith(text) ||
                branch.code.endsWith(text) ||
                branch.code === text ||
                branch.code.includes(text) ||
                branch.displayName.toUpperCase().includes(text.toUpperCase())
              )
        )),
        tap(() => {
          this.isSearching = false;
        })
      );
  }

  writeValue(newValue: Branch): void {
    this.branchValue = newValue;
  }
  registerOnChange(fn: OnChange<Branch>): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: OnTouched): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onBranchChange(branchEvent: NgbTypeaheadSelectItemEvent<Branch>): void {
    this.branchDropdownOption = 0;
    this.writeValue(branchEvent.item);
    this.onChange(branchEvent.item);
  }

  onBlur(): void {
    this.onTouched();
  }

  displayBranch(branch: Branch): string | null {
    if (!branch) {
      return;
    }

    const branchName: string = branch.displayName || branch.name;
    return [branch.code, branchName].join(' - ');
  }

  selectAllContent(target: HTMLInputElement): void {
    target.select();
  }

  onBranchKeydown(event: KeyboardEvent, branchSelectionGroup: HTMLElement): void {
    const branchMenu: HTMLElement = (branchSelectionGroup.querySelector(`ngb-typeahead-window`) as HTMLElement);
    if (branchMenu && (event.key.startsWith(`Arrow`) || event.code.startsWith(`Arrow`))) {
      const options: NodeListOf<Element> = document.querySelectorAll(`ngb-typeahead-window button`);
      const optionHeight: number = (options[0] as HTMLElement).offsetHeight;
      const currentPosition: number = this.branchDropdownOption ? this.branchDropdownOption * optionHeight : 0;
      const maxPosition: number = (options.length - 1) * optionHeight;
      let newPosition: number = currentPosition;
      if ((event.key === `ArrowDown`) || (event.code === `ArrowDown`)) {
        newPosition = (currentPosition + optionHeight) >= maxPosition ? maxPosition : currentPosition + optionHeight;
        this.branchDropdownOption++;
      } else if ((event.key === `ArrowUp`) || (event.code === `ArrowUp`)) {
        newPosition = (currentPosition - optionHeight) <= 0 ? 0 : currentPosition - optionHeight;
        this.branchDropdownOption--;
      }
      branchMenu.scrollTop = newPosition;
    } else {
      this.branchDropdownOption = 0;
    }
  }
}

