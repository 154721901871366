import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { CreateBillingRequest } from 'entities/create-billing-request';
import { ToastType } from "entities/toast-type";
import { CustomerReviewFormComponent } from "modals/customer-review-form/customer-review-form.component";
import { DeviceListModalComponent } from "modals/device-list-modal/device-list-modal.component";
import { BinLocationDictionary, OverrideBinLocationModalComponent } from "modals/override-bin-location-modal/override-bin-location-modal.component";
import { ProcessingPaymentFailedModalComponent } from 'modals/processing-payment-failed-modal-component/processing-payment-failed-modal-component.component';
import { ProcessingPaymentModalComponent } from 'modals/processing-payment-modal-component/processing-payment-modal-component.component';
import { of } from "rxjs";
import { catchError, map, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { BasketService } from "services/basket.service";
import { CartService } from "services/cart.service";
import { DownloadService } from 'services/download.service';
import { LoaderService } from "services/loader.service";
import { LoggerService } from 'services/logger.service';
import { PaymentService } from "services/payment.service";
import { ToastService } from "services/toast.service";
import { AppState } from "store/app-state";
import * as BranchSelectors from 'store/branch/branch.selectors';
import * as HomeActions from "store/home/home-page.actions";
import * as OrderConfirmationSelectors from "store/order-confirmation/order-confirmation.selectors";
import * as OrderConfirmationActions from './order-confirmation.actions';
import { CustomerReviewFormBagFee } from "./order-confirmation.entities";



@Injectable()
export class OrderConfimationEffects {
  public ProcessingPaymentModalRef: any = null;
  getDiscountsAfterPartAdded$ = createEffect(() =>
    this.action$.pipe(
      ofType(OrderConfirmationActions.getPartBinLocations),
      switchMap(({ items }) => {
        const test = items;
        return this.basketService.simulateTransferOrder({ items }).pipe(
          map((response) =>
            OrderConfirmationActions.setPartBinLocations({ partBins: response })
          )
        )
      })
    )
  );

  readyToPickButtonPressed$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(OrderConfirmationActions.readyToPickButtonPressed),
        withLatestFrom(
          this.store.select(OrderConfirmationSelectors.selectHasMultipleBins),
          this.store.select(OrderConfirmationSelectors.selectOrder),
          this.store.select(OrderConfirmationSelectors.selectSelectedOverrideBins),
        ),
        switchMap(([_, hasMultipleBins, order, selectedBins]) => {
          if (hasMultipleBins && order.orderItems.some(orderItem => orderItem.deliveryOption === "L")) {
            const modalRef = this.modalService.open(
              OverrideBinLocationModalComponent,
              {
                size: "lg",
                backdrop: "static",
                keyboard: false,
              }
            );
            return modalRef.result.then((partBins) => {
                this.store.dispatch(
                OrderConfirmationActions.selectPartBinLocations({ partBins })
              );
              return this.readyToPick(order, partBins).toPromise();
            });
          } else {
            const partBins = this.mapBinLocations(order.orderItems, selectedBins, order.order.sapOrderNo);
            this.store.dispatch(
              OrderConfirmationActions.selectPartBinLocations({ partBins })
            );
            return this.readyToPick(order, partBins);
          }
        })
      ),
    { dispatch: false }
  );

  clearOrderConfirmationOnHomePageLoad$ = createEffect(() =>
    this.action$.pipe(
      ofType(HomeActions.loaded),
      map(() => OrderConfirmationActions.clearOrderConfirmationData())
    )
  );


  displayDeviceList$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(OrderConfirmationActions.displayDeviceList),
        withLatestFrom(this.store.select(BranchSelectors.selectedBranch)),
        tap(async ([{request}, branch]) => {
          const modalRef = this.modalService.open(DeviceListModalComponent, {
            size: "md",
            backdrop: "static",
            keyboard: false,
          });
          await this.paymentService.getDeviceList(branch.code).toPromise().then(
            (devices) =>
              {
                if(!devices.length){
                  modalRef.close();
                  this.toastService.showToast("There are no devices.", ToastType.Error);
                  throw new Error("There are no devices.");
                }
                modalRef.componentInstance.devices = devices;
                modalRef.componentInstance.branchCode = branch.code;
                modalRef.result.then( (deviceKey: string) => {
                  const updatedRequest: CreateBillingRequest = {...request, paymentDeviceKey: deviceKey};
                  this.store.dispatch( OrderConfirmationActions.createBillingDownloadInvoice({request: updatedRequest}) );
                });
              },
            catchError(error => {
              this.toastService.showToast(error.errorMessage.message, error.errorMessage.type);
              return of(OrderConfirmationActions.displayDeviceListFailure({error}));
            })
          );
        })
      ),
    { dispatch: false }
  );

  displayCustomerReviewForm$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(OrderConfirmationActions.displayCustomerReviewForm),
        withLatestFrom(
          this.store.select(OrderConfirmationSelectors.getPaymentTypeInfo),
          this.store.select(OrderConfirmationSelectors.selectOrderConfirmationTotal)),
        tap(async ([{request}, payment, orderConfirmationTotal]) => {
          await this.cartService.getCustomerReviewForm(request).then(
             (res: CustomerReviewFormBagFee) => {
              const crfFileUrl = this.downloadService.getPdfUrl(res.pdf);
              const modalRef = this.modalService.open(CustomerReviewFormComponent, {
                size: "lg",
                backdrop: "static",
                keyboard: false,
              });
              modalRef.componentInstance.updateUrl(crfFileUrl);

              const bagFee = res.bagFee || 0;
              const taxAmount = res.taxAmount || 0;
              const totalWithoutTax = orderConfirmationTotal.total - orderConfirmationTotal.taxAmount;
              modalRef.componentInstance.paymentDue = totalWithoutTax + bagFee + taxAmount;

              modalRef.result.then( () => {

                // Pay online with credit card "CASH-CardInHand"
                if (payment.billToAddressNo === "CASH-CardInHand") {
                  this.store.dispatch(OrderConfirmationActions.displayDeviceList({ request }));
                } else {
                  this.store.dispatch( OrderConfirmationActions.createBillingDownloadInvoice({ request }) );
                }
              });
            }
          );
        })
      ),
    { dispatch: false }
  );

  acceptedDevicePaymentMessageReceived$ = createEffect(
    () =>
    this.action$.pipe(
      ofType(OrderConfirmationActions.acceptedDevicePaymentMessageReceived),
      withLatestFrom(this.store.select(OrderConfirmationSelectors.selectOrder)),
      switchMap(([deviceResponse, order]) => {
        const request: CreateBillingRequest = {
          orderNumber: order.order.sapOrderNo,
          lineItems: [],
          paymentDeviceKey: '',
          checkIntegrations: true
        };
        return this.cartService.createBilling(request)
        .then((response: any) => {
          if (response.pdf?.length) {
            this.modalService.dismissAll();
            const filename = `Invoice_${request.orderNumber}.pdf`;
            this.downloadService.downloadPDF(response.pdf, filename );
            this.loaderService.loading = false;
            this.toastService.showToast("Invoice Received", ToastType.Success);
          } else if (response.messages === "Create Billing already completed") {
            // show toast message
            this.toastService.showToast("Create Billing already completed.", ToastType.Success);
          } else if (response.messages === "Response not found") {
            // show toast message
            this.toastService.showToast("Please try again.", ToastType.Error);
          } else {
            this.modalService.dismissAll();
            let modalRef = null;
            modalRef = this.modalService.open(ProcessingPaymentFailedModalComponent, {
              size: "sm",
              backdrop: "static",
              keyboard: false,
            });
            modalRef.result.then( (result) => {
              this.store.dispatch(OrderConfirmationActions.displayDeviceList({ request })); // <= Test Code, need total
            });
          }
      })
      .catch((err) => {
        this.toastService.showToast("Get Invoice Failed.", ToastType.Error);
      });
    }),
  ),{ dispatch: false });

  faileddDevicePaymentMessageReceived$ = createEffect(
    () =>
    this.action$.pipe(
      ofType(OrderConfirmationActions.failingDevicePaymentMessageReceived),
      withLatestFrom(this.store.select(OrderConfirmationSelectors.selectOrder)),
      tap(([deviceResponse, order]) => {

        const request: CreateBillingRequest = {
          orderNumber: order.order.sapOrderNo,
          lineItems: [],
          paymentDeviceKey: '',
          checkIntegrations: true
        };

        this.modalService.dismissAll();
        let modalRef = null;
        modalRef = this.modalService.open(ProcessingPaymentFailedModalComponent, {
          size: "sm",
          backdrop: "static",
          keyboard: false,
        });
        modalRef.result.then( (result) => {
          this.store.dispatch(OrderConfirmationActions.displayDeviceList({ request }));
        });
    }),
  ),{ dispatch: false });


  getInvoiceButtonPressed$ = createEffect(
    () =>
    this.action$.pipe(
      ofType(OrderConfirmationActions.getInvoiceButtonPressed),
      withLatestFrom(this.store.select(OrderConfirmationSelectors.selectOrder)),
      switchMap(([_, order]) => {
        const request: CreateBillingRequest = {
          orderNumber: order.order.sapOrderNo,
          lineItems: [],
          paymentDeviceKey: '',
          checkIntegrations: true
        };
        return this.cartService.createBilling(request)
        .then((response: any) => {
          if (response.pdf?.length) {
            this.modalService.dismissAll();
            const filename = `Invoice_${request.orderNumber}.pdf`;
            this.downloadService.downloadPDF(response.pdf, filename );
            this.loaderService.loading = false;
            this.toastService.showToast("Invoice Received", ToastType.Success);
          } else if (response.messages === "Create Billing already completed") {
            // show toast message
            this.toastService.showToast("Create Billing already completed.", ToastType.Success);
          } else if (response.messages === "Response not found") {
            // show toast message
            this.toastService.showToast("Please try again.", ToastType.Error);
          } else {
            this.modalService.dismissAll();
            let modalRef = null;
            modalRef = this.modalService.open(ProcessingPaymentFailedModalComponent, {
              size: "sm",
              backdrop: "static",
              keyboard: false,
            });
            modalRef.result.then( (result) => {
              this.store.dispatch(OrderConfirmationActions.displayDeviceList({ request })); // <= Test Code, need total
            });
          }
      })
      .catch((err) => {
        this.toastService.showToast("Get Invoice Failed.", ToastType.Error);
      });
    }),
  ),{ dispatch: false });

  createBillingDownloadInvoice$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(OrderConfirmationActions.createBillingDownloadInvoice),
        tap(async ({request}) => {
            const req = {...request};
            req.checkIntegrations = false;
            let modalRef = null;
            if (req.paymentDeviceKey?.length > 1) {
              this.paymentService.createConnection(req.paymentDeviceKey); // Watch for incoming device ping for seleced device
              modalRef = this.modalService.open(ProcessingPaymentModalComponent, {
                size: "sm",
                backdrop: "static",
                keyboard: false,
              });
            } else {
              this.loaderService.loading = true;
            }
            await  this.cartService.createBilling(req)
              .then((response: any) => {
                if (response.pdf?.length) {
                  const filename = `Invoice_${request.orderNumber}.pdf`;
                  this.downloadService.downloadPDF(response.pdf, filename );
                  this.loaderService.loading = false;
                  this.toastService.showToast("Invoice Received", ToastType.Success);
                } else {
                  this.loaderService.loading = false;
                  if (response.messages === "Device woke successfully") {
                    // enable the button
                    this.ProcessingPaymentModalRef = modalRef;
                  }
                  if (response.messages !== "Device woke successfully") {
                    modalRef.close();
                    modalRef = this.modalService.open(ProcessingPaymentFailedModalComponent, {
                      size: "sm",
                      backdrop: "static",
                      keyboard: false,
                    });
                    modalRef.result.then( (result) => {
                      this.store.dispatch(OrderConfirmationActions.displayDeviceList({ request })); // <= Test Code, need total
                    });
                  }
                }
        })
        .catch(error => {
          modalRef.close();
          this.loaderService.loading = false;
        if (request.paymentDeviceKey?.length > 1) {
          modalRef = this.modalService.open(ProcessingPaymentFailedModalComponent, {
            size: "sm",
            backdrop: "static",
            keyboard: false,
          });
          modalRef.result.then( (result) => {
            switch (result) {
                case 'chooseAnotherDevice':
                  this.store.dispatch(OrderConfirmationActions.displayDeviceList({ request })); // <= Test Code, need total
                  break;
                case 'retry':
                  this.store.dispatch(OrderConfirmationActions.displayDeviceList({ request })); // <= Test Code, need total
                  break;
                  case 'canceled':
                  default:
                  break;
            }
          });
        } else {
          this.toastService.showToast("Incorrect chip-clip or quantity unavailable in bin " , ToastType.Error);
        }
        })
      })),
    { dispatch: false }
  );

  constructor(
    private action$: Actions,
    private store: Store<AppState>,
    private basketService: BasketService,
    private modalService: NgbModal,
    private cartService: CartService,
    private toastService: ToastService,
    private loaderService: LoaderService,
    private loggerService: LoggerService,
    private downloadService: DownloadService,
    private paymentService: PaymentService,
  ) {}

  readyToPick(order, partBins) {
    this.loaderService.loading = true;
    return this.cartService
    .readyToPick({
      orderNumber: order.order.sapOrderNo,
      selectedBinLocations: partBins,
    })
    .pipe(
      withLatestFrom(
        this.store.select(
          OrderConfirmationSelectors.selectReadyToPickEnabled
        )
      ),
      tap(([response, readyToPickEnabled]) => {
        if(response.pdf){
          const filename = "TO_" + order.order.sapOrderNo;
          this.downloadService.downloadPDF(response.pdf, filename);
        }
        this.loaderService.loading = false;
        const parsedMessages = response.messages.map(x => {
          const toastTypeString = x.type.toString().charAt(0).toUpperCase() + x.type.toString().slice(1);
          const type: ToastType = ToastType[toastTypeString];
          return {...x, type};
        });
        if(parsedMessages.some(x => x.type == ToastType.Error || x.type == ToastType.Warning)){
          this.toastService.showMultilineStaticToast(parsedMessages);
        } else {
          this.toastService.showMultilineToast(parsedMessages);
        }

        this.store.dispatch(
          OrderConfirmationActions.readyToPickSuccess()
        );
        if (!order.order.isCreateBillingEnabled && order.order.orderType !== "Quote") {
          this.store.dispatch(OrderConfirmationActions.setCreateBillingEnabled({ createBillingEnabled: false}));
        } else {
          this.store.dispatch(OrderConfirmationActions.setCreateBillingEnabled({ createBillingEnabled: true}));
        }
      }),
      catchError((err) => {
        this.loaderService.loading = false;
        this.toastService.showToast("Something Went Wrong.", ToastType.Error);
        return err;
      })
    );
  }

  mapBinLocations(orderItems: any[], overrideBins, orderNumber) {
    const _overrideBins: BinLocationDictionary = {};
      const _orderItems = orderItems?.map(x => {
        return {
          partNumber: x.partNumber,
          itemNum: x.itemNumber,
          orderNum: orderNumber,
          quantity: x.quantity
        };
      });

      _orderItems?.forEach(item => {
        const lookupBins: any[] = overrideBins[item.partNumber];
        if (lookupBins?.length) {
          const selectedBin = lookupBins.filter(x => x.isDefaultBin)[0]?.binLocation;
          const selectedBinType = lookupBins.filter(x => x.isDefaultBin)[0]?.storageType;
          _overrideBins[item.partNumber] = {...item, bins: lookupBins, selectedBin, selectedBinType};
        }
      });

      const selectedBins = Object.values(_overrideBins).map(x => {
        return {
          ItemNumber: x.itemNum,
          Quantity: x.quantity,
          StorageBin: x.selectedBin,
          StorageType: x.selectedBinType
        }
      });

      return selectedBins;
  }

}
