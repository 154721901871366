import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subject, Subscription, merge, tap } from 'rxjs';
import { BackCounterDashboardService } from 'services/back-counter-dashboard.service';
import { AppState } from 'store/app-state';
import { RequestNote } from 'store/back-counter/back-counter.entities';
import * as BackCounterToolActions from 'store/back-counter/back-counter.actions';

@Component({
  selector: 'app-back-counter-general-request-modal',
  templateUrl: './back-counter-general-request-modal.component.html',
  styleUrls: ['./back-counter-general-request-modal.component.scss']
})
export class BackCounterGeneralRequestModalComponent implements AfterViewInit, OnDestroy {

  @Input() updateGeneralRequest: boolean;
  @Input() isComplete: boolean;
  @Input() refOrderNumber: string;
  @Input() requestor: string;
  @Input() orderNumber: string;
  @Input() notes: RequestNote[];
  @Input() updateNote: Subject<{orderNumber: string; note: string;}>;
  // this subject is for adding notes (locally) that are pending a refresh event from sap
  @Input() addNote: Subject<{orderNumber: string; note: string}>;
  @ViewChild('generalRequestMessages') generalRequestMessagesRef: ElementRef;
  @ViewChild('generalRequestMessagesContainer') generalRequestMessagesContainerRef: ElementRef;
  note: string;

  subscription: Subscription;
  constructor(
    private store: Store<AppState>,
    private backCounterDashboardService: BackCounterDashboardService,
    private activeModal: NgbActiveModal){

  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  ngAfterViewInit(): void {
    if(this.updateNote) {
        const addNoteObs = this.addNote.pipe(
          tap(({ note }) => {
            const date = new Date();
            this.notes.unshift({
              date: date.toDateString(),
              noteText: note,
              techName: null,
              techNumber: null,
              userId: null,
              time: date.getTime().toString(),
            });

            const el: HTMLDivElement =
              this.generalRequestMessagesContainerRef.nativeElement;
            el.scrollTop = 0;

            this.note = "";
          })
        );
        this.subscription = addNoteObs.subscribe();
    }
  }

  dismissModal(){
    this.activeModal.dismiss();
  }

  submit(){
    if(this.updateGeneralRequest){
      this.updateNote.next({orderNumber: this.orderNumber, note: this.note });
    } else {
      this.activeModal.close({orderNumber: this.orderNumber, note: this.note});
    }
  }

  complete(){
    this.store.dispatch(BackCounterToolActions.completeGeneralRequest({orderNumber: this.orderNumber}));
    this.activeModal.close();
  }
}
