import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { FeatureFlag } from "store/feature-flags/feature-flags.state";

@Injectable({
    providedIn: "root"
})
export class FeatureFlagApiService {

    constructor(
        private httpClient: HttpClient
      ) { }

    public getFeatureFlags(): Observable<{ [FeatureName: string]: FeatureFlag }>  {
        const url = `api/FeatureFlags/GetFeatureFlags`;
        return this.httpClient.get<{ [FeatureName: string]: FeatureFlag }>(url);
    }
}
