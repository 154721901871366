<div *ngLet="{
  busy: busy$ | async
} as vm">
  <form [formGroup]="i2pForm" (ngSubmit)="onSubmit()" class="form-editable p-4 mb-2">
    <div>Send the customer a secure link to provide card details.</div>
    <div class="mt-1"><strong>Send invitation to:</strong></div>
    <div class="i2p-radio-group mt-1">
      <input id="i2p_EmailChoice" type="radio" formControlName="i2p" [value]="options.email">
      <label for="i2p_EmailChoice" class="i2p-choice">Email</label>
      <input id="i2p_SmsChoice" type="radio" formControlName="i2p" [value]="options.sms">
      <label for="i2p_SmsChoice" class="i2p-choice">SMS (charges apply)</label>
    </div>
    <div *ngIf="i2pForm.get('i2p').value === options.email">
      <input [ngClass]="{'invalid':i2pForm.controls.i2pEmail.errors}" id="i2pEmail"
        formControlName="i2pEmail" placeholder="xyz@domain.com" type="text">
    </div>
    <div *ngIf="i2pForm.get('i2p').value === options.sms">
      <input [ngClass]="{'invalid':i2pForm.controls.i2pSms.errors}"
        placeholder="123-456-7890" type="text" id="i2pSMS"
        formControlName="i2pSms" (keypress)="keyPressed($event)">
    </div>
    <div class="form-group">
      <button id="i2pSubmit" type="submit" class="btn btn-primary mt-2" [disabled]="!i2pForm.valid || vm.busy">Send</button>
      <span id="inviteToPayLoader" class="countLoader" *ngIf="vm.busy"></span>
    </div>
    <div class="alert alert-danger mt-3 mb-0" *ngIf="!i2pForm.valid" id="i2pValidation">
      <div *ngIf="i2pForm.controls.i2pEmail.errors?.required">Email is required.</div>
      <div *ngIf="i2pForm.controls.i2pEmail.errors?.email">Email format is incorrect.</div>
      <div *ngIf="i2pForm.controls.i2pSms.errors?.required">Phone number is required.</div>
      <div *ngIf="i2pForm.controls.i2pSms.errors?.pattern">Enter a valid phone number.</div>
    </div>
  </form>
</div>
