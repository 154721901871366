import { BackCounterNote } from "entities/notes";
import { BackCounterState } from "./back-counter.state";
import { BackCounterRequestDetails } from "./back-counter.entities";




export const openRequests = [
  {
    orderNumber: '123456',
    advisor: 'TEST ADVISOR',
    custUnitNo: 'TEST CUST UNIT NO',
    customerPo: 'TEST CUSTOMER PO',
    vin: 'TESTVIN',
    rtlUnitNo: 'TEST RTL UNIT NO',
    license: 'TEST LICENSE',
    dateInvoice: 'TEST DATE',
    engineMnf: 'TEST ENGINE MNF',
    transMnf: 'TEST TRANS MNF',
    fAxleMnf: 'TEST F AXLE MNF',
    fDiffMnf: 'TEST F DIFF MNF',
    rDiffMnf: 'TEST R DIFF MNF',
    suspension: 'TEST SUSPENSION',
    starter: 'TEST STARTER',
    clutch: 'TEST CLUTCH',
    mileage: 'TEST MILEAGE',
    vehicleYear: 'TEST YEAR',
    makeModel: 'TEST MAKE/MODEL',
    vehicleType: 'TEST TYPE',
    engineHrs: 'TEST ENGINE HRS',
    engineMod: 'TEST ENGINE MOD',
    transMod: 'TEST TRANS MOD',
    fAxleMod: 'TEST F AXEL MOD',
    fDiffMod: 'TEST F DIFF MOD',
    rDiffMod: 'TEST R DIFF MOD',
    cplArrange: 'TEST CPL ARRANGE',
    alternator: 'TEST ALTERNATOR',
    fanClutch: 'TEST FAN CLUTCH',
    primaryContact: 'TEST PRIMARY CONTACT',
    primaryPhone: 'TEST PRIMARY PHONE',
    driverContact: 'TEST DRIVER CONTACT',
    driverPhone: 'TEST DRIVER PHONE',
    engineSno: 'TEST ENGINE S NO',
    transSno: 'TEST TRANS S NO',
    fAxle: 'TEST F AXEL',
    fDiffS: 'TEST F DIFF S',
    rDiffS: 'TEST R DIFF S',
    catCabType: 'TEST CAT CAB TYPE',
    paint: 'TEST PAINT',
    keyCode: 'TEST KEY CODE',
    bayNo: 'TEST BAY NUMBER',
    ordertogetjob: [
      {
        jobNumber: '1',
        jobDescription: 'OIL Pan kit and Parts'
      },
      {
        jobNumber: '2',
        jobDescription: 'Other things to fix a truck.'
      }
    ],
    ordertogetitem: [
      {
        jobNumber: '1',
        itemNumber: '1',
        materialNumber: 'AC1001:PB',
        orderQuantity: 3,
        purchaseVendor: 'TEST VENDOR',
        freight: 'TEST FREIGHT'
      },
      {
        jobNumber: '1',
        itemNumber: '2',
        materialNumber: 'BS123:PB',
        orderQuantity: 9999,
        purchaseVendor: 'TEST VENDOR',
        freight: 'TEST FREIGHT'
      },
      {
        jobNumber: '2',
        itemNumber: '1',
        materialNumber: 'NOTHING:PB',
        orderQuantity: 1,
        purchaseVendor: 'TEST VENDOR',
        freight: 'TEST FREIGHT'
      }
    ],
    branch: '1001',
    customerNo: '110047',
    techName: 'Bobinsky Smithovich',
    tagNo: '99999',
    photoExists: true,
    attachments: []
  }
];
export const BackCounterStateMock: any = {
  totalRequestCount: 2,
  requests: {
    summaryList: [{
      id: "1234",
      creationDate: "3/23/2023",
      open: "08.00.01",
      priority: "Medium",
      type: "Quote",
      orderNumber: "31245682",
      customer: "Cleveland",
      job: "10",
      jobDescription: "Brake Parts",
      oem: "PB",
      mobile: "Test",
      assignedTo: "Duck, Donald",
      notes: [],
      complete: "",
      isCustomerWaiting: "false",
      department: "service",
      branchCode: "1001",
      reqType: "90"
    }, {
      id: "123456",
      creationDate: "3/23/2023",
      open: "08.00.01",
      priority: "High",
      type: "Quote",
      orderNumber: "31245682",
      customer: "Cleveland",
      job: "10",
      jobDescription: "Engine Parts",
      oem: "PB",
      mobile: "Test",
      assignedTo: "Duck, Donald",
      notes: [],
      complete: "true",
      isCustomerWaiting: "true",
      department: "service",
      branchCode: "1024",
      reqType: "0"
    }, {
      id: "56845",
      creationDate: "3/23/2023",
      open: "08.00.01",
      priority: "Low",
      type: "Quote",
      orderNumber: "31245682",
      customer: "Cleveland",
      job: "10",
      jobDescription: "Oil change",
      assignedTo: "Duncan, Joe",
      oem: "PB",
      mobile: "Test",
      notes: [],
      complete: "true",
      isCustomerWaiting: "true",
      department: "service",
      branchCode: "1024",
      reqType: "90"
    }, {
      id: "112233",
      creationDate: "3/23/2023",
      open: "08.00.01",
      priority: "Medium",
      type: "Quote",
      orderNumber: "25154825",
      customer: "Cleveland",
      job: "10",
      jobDescription: "Brake Parts",
      oem: "PB",
      mobile: "Test",
      assignedTo: "Duck, Donald",
      notes: [],
      complete: "",
      isCustomerWaiting: "false",
      department: "service",
      branchCode: "1024",
      reqType: "0"
    }, {
      id: "445566",
      creationDate: "3/23/2023",
      open: "08.00.01",
      priority: "High",
      type: "Quote",
      orderNumber: "25154825",
      customer: "Cleveland",
      job: "10",
      jobDescription: "Engine Parts",
      oem: "PB",
      mobile: "Test",
      assignedTo: "Duck, Donald",
      notes: [],
      complete: "",
      isCustomerWaiting: "false",
      department: "bodyshop",
      branchCode: "1001",
      reqType: "90"
    }, {
      id: "778899",
      creationDate: "3/23/2023",
      open: "08.00.01",
      priority: "Low",
      type: "Quote",
      orderNumber: "25154825",
      customer: "Cleveland",
      job: "10",
      jobDescription: "Oil change",
      oem: "PB",
      mobile: "Test",
      assignedTo: "Duck, Donald",
      notes: [],
      complete: "true",
      isCustomerWaiting: "true",
      department: "bodyshop",
      branchCode: "0122",
      reqType: "01"
    }],
    totalCount: 10,
    selectedRequest:  {
      orderNumber: '123456',
      advisor: 'TEST ADVISOR',
      custUnitNo: 'TEST CUST UNIT NO',
      customerPo: 'TEST CUSTOMER PO',
      vin: 'TESTVIN',
      rtlUnitNo: 'TEST RTL UNIT NO',
      license: 'TEST LICENSE',
      dateInvoice: 'TEST DATE',
      engineMnf: 'TEST ENGINE MNF',
      transMnf: 'TEST TRANS MNF',
      fAxleMnf: 'TEST F AXLE MNF',
      fDiffMnf: 'TEST F DIFF MNF',
      rDiffMnf: 'TEST R DIFF MNF',
      suspension: 'TEST SUSPENSION',
      starter: 'TEST STARTER',
      clutch: 'TEST CLUTCH',
      mileage: 'TEST MILEAGE',
      vehicleYear: 'TEST YEAR',
      makeModel: 'TEST MAKE/MODEL',
      vehicleType: 'TEST TYPE',
      engineHrs: 'TEST ENGINE HRS',
      engineMod: 'TEST ENGINE MOD',
      transMod: 'TEST TRANS MOD',
      fAxleMod: 'TEST F AXEL MOD',
      fDiffMod: 'TEST F DIFF MOD',
      rDiffMod: 'TEST R DIFF MOD',
      cplArrange: 'TEST CPL ARRANGE',
      alternator: 'TEST ALTERNATOR',
      fanClutch: 'TEST FAN CLUTCH',
      primaryContact: 'TEST PRIMARY CONTACT',
      primaryPhone: 'TEST PRIMARY PHONE',
      driverContact: 'TEST DRIVER CONTACT',
      driverPhone: 'TEST DRIVER PHONE',
      engineSno: 'TEST ENGINE S NO',
      transSno: 'TEST TRANS S NO',
      fAxle: 'TEST F AXEL',
      fDiffS: 'TEST F DIFF S',
      rDiffS: 'TEST R DIFF S',
      catCabType: 'TEST CAT CAB TYPE',
      paint: 'TEST PAINT',
      keyCode: 'TEST KEY CODE',
      bayNo: 'TEST BAY NUMBER',
      ordertogetjob: [
        {
          jobNumber: '1',
          jobDescription: 'OIL Pan kit and Parts'
        },
        {
          jobNumber: '2',
          jobDescription: 'Other things to fix a truck.'
        }
      ],
      ordertogetitem: [
        {
          deliveryDate: null,
          description: null,
          freight: 'TEST FREIGHT',
          itemCategory: null,
          itemNotifflag: null,
          itemNumber: '1',
          itmcancrea: null,
          jobNumber: '1',
          mainPrItem: null,
          materialNumber: 'AC1001:PB',
          orderNumber: null,
          orderQuantity: 3,
          orderUom: null,
          partLocation: null,
          prItemStatus: null,
          prItemStatusDesc: null,
          prRefMaterial: null,
          purchaseVendor: 'TEST VENDOR'
        },
        {
          deliveryDate: null,
          description: null,
          freight: 'TEST FREIGHT',
          itemCategory: null,
          itemNotifflag: null,
          itemNumber: '1',
          itmcancrea: null,
          jobNumber: '1',
          mainPrItem: null,
          materialNumber: 'AC1001:PB',
          orderNumber: null,
          orderQuantity: 3,
          orderUom: null,
          partLocation: null,
          prItemStatus: null,
          prItemStatusDesc: null,
          prRefMaterial: null,
          purchaseVendor: 'TEST VENDOR'
        }
      ],
      branch: '1001',
      customerNo: '110047',
      techName: 'Bobinsky Smithovich',
      tagNo: '99999',
      photoExists: true,
      attachments: []
    },
  },
  openRequests: [],
  loading: false
}



export const BackCounterRequestOverviewMock = {
  "1001": {
    totalCount: 16,
    quoteCount: 4,
    orderCount: 4,
    unassignedCount: 4,
    inprocessCount: 4
  },
  "1024": {
    totalCount: 10,
    quoteCount: 2,
    orderCount: 2,
    unassignedCount: 4,
    inprocessCount: 2
  }
}



export const BackCounterRequestDetailsMock = {
  isLocked: false,
  orderNumber: "38289682",
  advisor: "TEST ADVISOR",
  custUnitNo: "TEST CUST UNIT NO",
  customerPo: "TEST CUSTOMER PO",
  vin: "TESTVIN",
  rtlUnitNo: "TEST RTL UNIT NO",
  license: "TEST LICENSE",
  dateInvoice: "TEST DATE",
  engineMnf: "TEST ENGINE MNF",
  transMnf: "TEST TRANS MNF",
  fAxleMnf: "TEST F AXLE MNF",
  fDiffMnf: "TEST F DIFF MNF",
  rDiffMnf: "TEST R DIFF MNF",
  suspension: "TEST SUSPENSION",
  starter: "TEST STARTER",
  clutch: "TEST CLUTCH",
  mileage: "TEST MILEAGE",
  vehicleYear: "TEST YEAR",
  makeModel: "TEST MAKE/MODEL",
  vehicleType: "TEST TYPE",
  engineHrs: "TEST ENGINE HRS",
  engineMod: "TEST ENGINE MOD",
  transMod: "TEST TRANS MOD",
  fAxleMod: "TEST F AXEL MOD",
  fDiffMod: "TEST F DIFF MOD",
  rDiffMod: "TEST R DIFF MOD",
  cplArrange: "TEST CPL ARRANGE",
  alternator: "TEST ALTERNATOR",
  fanClutch: "TEST FAN CLUTCH",
  primaryContact: "TEST PRIMARY CONTACT",
  primaryPhone: "TEST PRIMARY PHONE",
  driverContact: "TEST DRIVER CONTACT",
  driverPhone: "TEST DRIVER PHONE",
  engineSno: "TEST ENGINE S NO",
  transSno: "TEST TRANS S NO",
  fAxle: "TEST F AXEL",
  fDiffS: "TEST F DIFF S",
  rDiffS: "TEST R DIFF S",
  catCabType: "TEST CAT CAB TYPE",
  paint: "TEST PAINT",
  keyCode: "TEST KEY CODE",
  bayNo: "TEST BAY NUMBER",
  ordertogetjob: [
    {
      jobNumber: "1",
      jobDescription: "OIL Pan kit and Parts"
    },
    {
      jobNumber: "2",
      jobDescription: "Other things to fix a truck."
    }
  ],
  ordertogetitem: [
    {
      jobNumber: "1",
      itemNumber: "1",
      materialNumber: "AC1001:PB",
      orderQuantity: 3,
      purchaseVendor: "TEST VENDOR",
      frieght: "TEST FREIGHT"
    },
    {
      jobNumber: "1",
      itemNumber: "2",
      materialNumber: "BS123:PB",
      orderQuantity: 9999,
      purchaseVendor: "TEST VENDOR",
      frieght: "TEST FREIGHT"
    },
    {
      jobNumber: "2",
      itemNumber: "1",
      materialNumber: "NOTHING:PB",
      orderQuantity: 1,
      purchaseVendor: "TEST VENDOR",
      frieght: "TEST FREIGHT"
    }
  ],
  branch: "1001",
  customerNo: "110047",
  techName: "Bobinsky Smithovich",
  tagNo: "99999",
  photoExists: true,
  attachments: []
   } as BackCounterRequestDetails;
