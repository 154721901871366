import { Inject, Injectable, inject } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors } from '@angular/forms';
import { CheckoutValidationMessages } from 'app/_components/checkout-validation/checkout-validation.messages';
import {
  AdditionalDetailsFormGroup, CheckoutDetailsFormGroup, CheckoutFormGroup,
  CheckoutSmsOptFormGroup, PaymentDetailsFormGroup,
  ShippingAndDeliveryFormGroup, ShippingAndDeliveryShipToFormGroup
} from 'app/_components/checkout/checkout.component';
import { REGEX_CANADA_POSTAL_PATTERN, REGEX_PHONE_PATTERN, REGEX_USA_POSTAL_PATTERN, UNIT_NUMBER_PATTERN } from 'app/app.constants';
import { billToType, i2pType, selectedPayment } from 'entities/checkout-billing-enums';
import { DeliveryItems } from 'entities/delivery-items';
import { OrderType } from 'entities/order-type';
import { additionalDetailsValidators, paymentDetailsValidators, procurementValidator, shipToValidator, validateOrderChannelRequired, validatePONumberPattern, validatePONumberRequired, validateTransactionTypeRequired, validateUnitNumberPattern } from 'validators/checkout';
import { FeatureFlagService } from './feature-flag.service';

@Injectable({
  providedIn: "root"
})
export class CheckoutFormService {
  public checkoutForm: FormGroup<CheckoutFormGroup>;

  constructor(
    private featureFlagService: FeatureFlagService,
    private formBuilder: FormBuilder,
    @Inject(REGEX_CANADA_POSTAL_PATTERN) public regexCanadaPostalPattern: RegExp,
    @Inject(REGEX_USA_POSTAL_PATTERN) public regexUSAPostalPattern: RegExp,
    @Inject(REGEX_PHONE_PATTERN) public regexPhonePattern: RegExp
  ) {
    this.initForm();
  }

  private unitNumberPattern = inject(UNIT_NUMBER_PATTERN);


  public initForm(): void {
    const isCustomerNotesFeatureEnabled = this.featureFlagService.isFeatureActive("FeatureFlag.CustomerNotes.Enabled");
    const newForm = this.formBuilder.group<CheckoutFormGroup>({
      checkoutDetails: this.formBuilder.group<CheckoutDetailsFormGroup>({
        transactionType: this.formBuilder.control<OrderType | null>(null),
        orderChannel: this.formBuilder.control(null),
        poNumber: this.formBuilder.control(''),
        unitNumber: this.formBuilder.control('')
      }, {
        validators: [
          validateTransactionTypeRequired(),
          validateOrderChannelRequired(),
          validatePONumberPattern(),
          validatePONumberRequired(),
          validateUnitNumberPattern()
        ]
      }),
      shippingAndDelivery: this.formBuilder.group<ShippingAndDeliveryFormGroup>({
        deliveryItems: this.formBuilder.control(DeliveryItems.all),
        deliveryType: this.formBuilder.control(''),
        cartData: this.formBuilder.control(null),
        shipTo: this.formBuilder.group<ShippingAndDeliveryShipToFormGroup>({
          addressNumber: this.formBuilder.control(null),
          city: this.formBuilder.control(null),
          customerName: this.formBuilder.control(null),
          customerName2: this.formBuilder.control(null),
          customerNumber: this.formBuilder.control(null),
          mscAccountNumber: this.formBuilder.control(null),
          phoneNumber: this.formBuilder.control(null),
          postalCode: this.formBuilder.control(null),
          salesperson: this.formBuilder.control(null),
          state: this.formBuilder.control(null),
          streetAddress: this.formBuilder.control(null),
          isEdited: this.formBuilder.control(false),
          isCanadaUser: this.formBuilder.control(false),
          shippingManagementEnabled: this.formBuilder.control(false)
        }),
        freightdeliveryoption: this.formBuilder.control('')
      }, {
        validators: [
          procurementValidator(),
          shipToValidator(
            this.regexPhonePattern,
            this.regexUSAPostalPattern,
            this.regexCanadaPostalPattern
          )
        ]
      }),
      paymentDetails: this.formBuilder.group<PaymentDetailsFormGroup>({
        selectedPayment: this.formBuilder.control(''),
        billTo: this.formBuilder.control(''),
        billToCC: this.formBuilder.control(null),
        i2p: this.formBuilder.control('Email'),
        i2pEmail: this.formBuilder.control(''),
        i2pSms: this.formBuilder.control(''),
        addedCCResult: this.formBuilder.control(''),
      }, {
        validators: paymentDetailsValidators
      }),
      additionalDetails: this.formBuilder.group<AdditionalDetailsFormGroup>({
        altEmail: this.formBuilder.control(""),
        specialInstructions: this.formBuilder.control("")
      }),
      smsOpt: this.formBuilder.group<CheckoutSmsOptFormGroup>({
        optIn: this.formBuilder.control(false),
        contactName: this.formBuilder.control(null),
        mobileNumber: this.formBuilder.control(null)
      }),
      procuredAllLines: this.formBuilder.control(false),
      clearShoppingBasket: this.formBuilder.control(false),
      customer: this.formBuilder.control(null)
    }, {
      validators: additionalDetailsValidators
    });
    this.checkoutForm = newForm;
  }

  getAllValidators(): any {
    return this.recursiveValidators(this.checkoutForm);
  }

  private recursiveValidators(control: any): any {
    if (control instanceof FormGroup) {
      let validators = {};
      const formGroup = control as FormGroup;

      Object.keys(formGroup.controls).forEach(key => {
        const subControl = formGroup.get(key);
        const subValidators = this.recursiveValidators(subControl);

        if (subValidators) {
          validators = { ...validators, [key]: subValidators };
        }
      });

      return validators;
    } else {
      const controlValidators = control.validator && control.validator(control);

      return controlValidators ? { [control.name]: controlValidators } : null;
    }
  }

  i2pValidator(control: FormGroup<PaymentDetailsFormGroup>): ValidationErrors | null {
    const paymentDetailsFormGroup = control.parent as FormGroup<PaymentDetailsFormGroup>;
    const emailValue = paymentDetailsFormGroup?.value.i2pEmail;
    const smsValue = paymentDetailsFormGroup?.value.i2pSms;
    const emailEmpty = emailValue === null || emailValue === undefined || emailValue === '';
    const smsEmpty = smsValue === null || smsValue === undefined || smsValue === '';

    const i2pValue = paymentDetailsFormGroup?.value.i2p;
    const i2pEmailControl = paymentDetailsFormGroup?.controls.i2pEmail;
    const i2pSmsControl = paymentDetailsFormGroup?.controls.i2pSms;
    const billToValue = paymentDetailsFormGroup?.value.billTo;
    const billToCCValue = paymentDetailsFormGroup?.value.billToCC

    const isCCI2P = selectedPayment.CCI2P === billToCCValue;
    const isCASH_CCOnline = billToType.CASH_CCOnline === billToValue;

    const isEmailSelected = i2pType.Email.toLowerCase() === i2pValue;
    const isSmsSelected = i2pType.SMS.toLocaleLowerCase() === i2pValue;

    const validEmail = isCASH_CCOnline && isEmailSelected && !emailEmpty && i2pEmailControl.valid;
    const validSms = isCASH_CCOnline && isSmsSelected && !smsEmpty && i2pSmsControl.valid;

    if(isCCI2P && (!validEmail && !validSms)) {
      return { required: CheckoutValidationMessages['i2p.required'] };
    }
    return null;
  }
}
