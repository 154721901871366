<button *ngFor="let option of options$ | async"
  ngbDropdownItem [ngClass]="{ 'active': option.value }" [disabled]="disabled"
  [ngSwitch]="option.key" (click)="toggleOption(option.key)">
  <ng-container *ngSwitchCase="'notApplicable'">N/A</ng-container>
  <ng-container *ngSwitchCase="'delivered'">Delivered</ng-container>
  <ng-container *ngSwitchCase="'enRoute'">Out for Delivery</ng-container>
  <ng-container *ngSwitchCase="'preparing'">Preparing Order</ng-container>
  <ng-container *ngSwitchCase="'notDelivered'">Not to be Delivered</ng-container>
  <ng-container *ngSwitchCase="'shipped'">Shipped</ng-container>
</button>
<div class="dropdown-divider"></div>
<button ngbDropdownItem class="text-end" [disabled]="disabled" (click)="clear()">
  <i class="fas fa-times"></i>
  Clear
</button>
