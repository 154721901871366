<button id="PriceVerifyModal_Button_Close" aria-label="Close" class="close" data-dismiss="modal" tabindex="-1" type="button" (click)="closeModal(null)">
  <span aria-hidden="true">&times;</span>
</button>
<div class="modal-body">
  <div class='price-verify-msc'>
    <b>Verify or edit the account number below.</b>
    <div style="text-align:left; width:50%; margin:10px auto;">
      <div class="form-group">
        <label for="BCT_PriceVerifyModal_Text_MSCCardNumber">MSC/FC Card Number</label>
        <input disabled id="BCT_PriceVerifyModal_Text_MSCCardNumber" class="form-control" name="BCT_PriceVerifyModal_Text_MSCCardNumber" [value]="mscCardNumber">
      </div>
      <button id="PriceVerifyModal_Button_Continue" (click)="closeModal(mscCardNumber);" class="btn btn-default yellow-btn" style="width:100%">Continue</button>
    </div>
  </div>
</div>
