import { Injectable, ErrorHandler } from '@angular/core';
import { LoggerService } from 'services/logger.service';
import { LoaderService } from 'services/loader.service';

@Injectable()
export class AppErrorHandler implements ErrorHandler {

    constructor(
        private loggerService: LoggerService,
        private loader: LoaderService
    ) {}

    handleError(error: Error): void {
      this.loggerService.trackException(error);
      const message = error.stack !== undefined ? error.stack : error;
      this.loggerService.critical("Uncaught Exception", message);
      this.loader.loading = false;
    }
}
