import { Action, createReducer, on } from '@ngrx/store';
import * as actions from './back-counter.actions';
import { BackCounterConfig } from './back-counter.config';
import { BackCounterRequestDetails } from './back-counter.entities';
import { getUnsavedChanges, patchRequestPartItems } from './back-counter.reducer.util';
import { BackCounterState } from './back-counter.state';

export const initialState: BackCounterState = {
  requests: [],
  openRequests: [],
  loading: false,
  totalRequestCount: 0,
  requestDetailsLoading: false,
  unsavedChanges: {},
  isConfirmationLoading: false,
  priceVerifyItems: {}
};

export const backCounterReducer = createReducer<BackCounterState, Action>(
  initialState,
  on(actions.getBackCounterRequests, (state: BackCounterState) => ({
    ...state,
    loading: true
  })),
  on(actions.getBackCounterRequestsSuccess, (state: BackCounterState, { requests }) => ({
    ...state,
    requests: requests.summaryList,
    totalRequestCount: requests.totalCount,
    loading: false
  })),
  on(actions.getBackCounterRequestsFailed, (state: BackCounterState) => ({
    ...state,
    loading: false
  })),
  on(actions.closeBackCounterRequest, (state: BackCounterState, { requestId }) => ({
    ...state,
    selectedRequest: null, openRequests: state.openRequests.filter(x => x.orderNumber !== requestId)
  })),
  on(actions.openBackCounterRequest, (state: BackCounterState, { orderNumber }) => {
    let openRequests: BackCounterRequestDetails[] = [];
    if (!state.openRequests.find((r) => r.orderNumber === orderNumber) && state.openRequests.length < BackCounterConfig.activeRequestsLimit) {
      const newRequest = { orderNumber } as BackCounterRequestDetails;
      openRequests = [...state.openRequests, newRequest];
    } else {
      openRequests = state.openRequests;
    }
    return ({
      ...state,
      openRequests,
       requestDetailsLoading: true
    });
  }),
  on(actions.editBackCounterRequest, (state: BackCounterState) => ({
    ...state,
    requestDetailsLoading: true,
  })),
  on(actions.getRequestDetailsSuccess, (state: BackCounterState, { requestDetails }) => {
    const openRequests: BackCounterRequestDetails[] = [...state.openRequests];
    const requestIndex = openRequests.findIndex((r) => r.orderNumber === requestDetails.orderNumber);

    const attachmentsLoaded = openRequests[requestIndex]?.photoExists &&
    openRequests[requestIndex].attachments?.length > 0 &&
    openRequests[requestIndex].orderNumber === requestDetails.orderNumber;

    const attachments = attachmentsLoaded ? openRequests[requestIndex].attachments : requestDetails.attachments;
    openRequests[requestIndex] = {
      ...requestDetails,
      attachments
    };

    return ({
      ...state,
      openRequests,
      requestDetailsLoading: false
    });
  }),
  on(actions.getRequestDetailsFailed, (state: BackCounterState) => ({
    ...state,
    requestDetailsLoading: false
  })),
  on(actions.getRequestAttachmentsSuccess, (state: BackCounterState, { attachments, orderNumber }) => ({
    ...state,
    openRequests: state.openRequests.map(
      item => item.orderNumber === orderNumber ? {
        ...item,
        attachments:[...attachments],
    } : item )
  })),
  on(
    actions.syncUnsavedChanges, (state: BackCounterState, { orderNumber, editedParts, part, props }) => {
    const parts = state.openRequests.find(r => r.orderNumber === orderNumber)?.ordertogetitem;
    const patchedParts = patchRequestPartItems([...parts], part, props);
    return {
      ...state,
      openRequests: state.openRequests.map(
        item => item.orderNumber === orderNumber ? {
          ...item,
          ordertogetitem: patchedParts
      } : item ),
      unsavedChanges: {
        ...state.unsavedChanges,
        [orderNumber]: getUnsavedChanges(editedParts, patchedParts.map(x => ({...x, manualPrice: Number(x.manPrice)})))
      },
    }
  }),
  on(actions.updateBackCounterRequest, (state: BackCounterState) => ({
    ...state,
    requestDetailsLoading: true
  })),
  on(actions.updateBackCounterRequestSuccess, (state: BackCounterState) => ({
    ...state,
    priceVerifyItems: {},
    requestDetailsLoading: false
  })),
  on(actions.updateBackCounterRequestFailed, (state: BackCounterState) => ({
    ...state,
    requestDetailsLoading: false
  })),
  on(actions.completeConfirmed, (state: BackCounterState, { lockConfirm }) => ({
    ...state,
    isConfirmationLoading: lockConfirm
  })),
  on(actions.changeLoading, (state: BackCounterState, { loading }) => ( {
    ...state,
    loading
  })),
  on(actions.BCTPriceVerifySuccess, (state, {priceVerifyParts, requestId}) => {
    const verifiedItemsForRequest = priceVerifyParts.priceVerifyItems.reduce((prev, curr) => {
      prev[curr.partNumber] = curr;
      return prev;
    }, {});
    const priceVerifyItems = {...state.priceVerifyItems};
    priceVerifyItems[requestId] = verifiedItemsForRequest;

    const openRequests = state.openRequests;
    const openRequest = {...state.openRequests.find(x => x.orderNumber === requestId)};
    if(openRequest){
      openRequest.ordertogetitem = openRequest.ordertogetitem.map(x => {
        const priceVerifiedItem = priceVerifyParts.priceVerifyItems.find(y => y.partNumber === x.materialNumber);
        return {...x, isPriceVerified: priceVerifiedItem?.isPriceVerified};
      });
    }
    return ({...state, priceVerifyItems, openRequests});
  })
);
