
export interface OrderDetailsLineItem {
  orderConfirmationNumber: string;
  lineItemNumber: string;
  partNumber: string;
  description: string;
  quantity: number;
  unitPrice: number;
  isInventoried: boolean;
  isCore: boolean;
  isHidden: boolean;
  sapCoupons: any;
  deliveryStatus: OrderLineItemDeliveryStatus;
  deliveryOption: OrderLineCartItemDeliveryOption;
  tax: number;
  hasFreightTrackingNumber?: boolean;
  deliveryFee: number;
  deliveryFeeCredit: number;
  bagFee: number;
}

export enum OrderItemStatus {
  open = 'open',
  invoiced = 'invoiced',
  expired = 'expired',
  closed = 'closed'
}

export enum DeliveryOptions {
  NotApplicable = 0,
  WillCall = 1,
  AdHoc =  2,
  SelfPick = 3,
  FcKnown = 4,
  RtcDelivery = 5,
  ShipDirect = 6
}

export type OrderLineCartItemDeliveryOption =
  | 'notApplicable'
  | 'willCall'
  | 'adHoc'
  | 'selfPick'
  | 'fcKnown'
  | 'rtcDelivery'
  | 'freight'
  | 'shipDirect';

export type OrderLineItemDeliveryStatus =
  | 'notApplicable'
  | 'notPicked'
  | 'picked'
  | 'ready'
  | 'enRoute'
  | 'delivered'
  | 'notDelivered'
  | 'shipped';

export type OrderLineItemDeliveryStatusOption =
  | 'notApplicable'
  | 'delivered'
  | 'enRoute'
  | 'preparing'
  | 'notDelivered'
  | 'shipped';
