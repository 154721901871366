/* eslint-disable arrow-body-style */
import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from "@angular/forms";
import { CheckoutValidationMessages } from "app/_components/checkout-validation/checkout-validation.messages";
import { CheckoutFormGroup, PaymentDetailsFormGroup } from "app/_components/checkout/checkout.component";
import {
  billToType,
  i2pType,
  selectedPayment,
} from "entities/checkout-billing-enums";
import { OrderType } from "entities/order-type";
import { isNullOrEmpty } from "validators/null-or-empty.validator";

const validateBillTo = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const paymentDetailsFormGroup =
      control as FormGroup<PaymentDetailsFormGroup>;
    const billToControlValue = paymentDetailsFormGroup.value.billTo;
    if (isNullOrEmpty(billToControlValue)) {
      return { "billTo.valid": CheckoutValidationMessages["billTo.valid"] };
    }
    return null;
  };
};

const validateI2P = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const paymentDetailsFormGroup =
      control as FormGroup<PaymentDetailsFormGroup>;
    const i2pValue = paymentDetailsFormGroup?.value.i2p;
    const billToValue = paymentDetailsFormGroup?.value.billTo;
    const billToCCValue = paymentDetailsFormGroup?.value.billToCC;
    const isCCI2P = selectedPayment.CCI2P === billToCCValue;
    const isCASH_CCOnline = billToType.CASH_CCOnline === billToValue;
    const isEmailSelected = i2pType.Email.toLowerCase() === i2pValue;
    const isSmsSelected = i2pType.SMS.toLocaleLowerCase() === i2pValue;
    if (isCCI2P && isCASH_CCOnline && !isEmailSelected && !isSmsSelected) {
      return { "i2p.required": CheckoutValidationMessages["i2p.required"] };
    }
    return null;
  };
};

const validateI2PEmailFormat = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const paymentDetailsFormGroup =
      control as FormGroup<PaymentDetailsFormGroup>;
    const i2pEmailControlValue = paymentDetailsFormGroup.value.i2pEmail;
    if (!isNullOrEmpty(i2pEmailControlValue) && !emailRegex.test(i2pEmailControlValue)) {
      return { "i2pEmail.email": CheckoutValidationMessages["i2pEmail.email"] };
    }
    return null;
  };
};

const validateI2PEmailRequired = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const paymentDetailsFormGroup =
      control as FormGroup<PaymentDetailsFormGroup>;
    const i2pValue = paymentDetailsFormGroup?.value.i2p;
    const i2pEmailControlValue = paymentDetailsFormGroup.value.i2pEmail;
    if (i2pValue === i2pType.Email.toLowerCase() && isNullOrEmpty(i2pEmailControlValue)) {
      return { "i2pEmail.required": CheckoutValidationMessages["i2pEmail.required"] };
    }
    return null;
  };
};

const validateI2PSmsRequired = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const paymentDetailsFormGroup =
      control as FormGroup<PaymentDetailsFormGroup>;
    const i2pSmsControlValue = paymentDetailsFormGroup.value.i2pSms;
    const i2pValue = paymentDetailsFormGroup?.value.i2p;
    if (i2pValue === i2pType.SMS.toLowerCase() && isNullOrEmpty(i2pSmsControlValue)) {
      return { "i2pSms.required": CheckoutValidationMessages["i2pSms.required"] };
    }
    return null;
  };
};

const validateI2PSmsFormat = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const phoneRegex =
      /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
    const paymentDetailsFormGroup =
      control as FormGroup<PaymentDetailsFormGroup>;
    const i2pSmsControlValue = paymentDetailsFormGroup.value.i2pSms;
    if (!isNullOrEmpty(i2pSmsControlValue) && !phoneRegex.test(i2pSmsControlValue)) {
      return { "i2pSms.pattern": CheckoutValidationMessages["i2pSms.pattern"] };
    }
    return null;
  };
};

const validateCreditCard = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const paymentDetailsFormGroup =
      control as FormGroup<PaymentDetailsFormGroup>;
    const billToValue = paymentDetailsFormGroup.value.billTo;
    const billToCCValue = paymentDetailsFormGroup.value.billToCC;
    const selectedPaymentValue = paymentDetailsFormGroup.value.selectedPayment;
    if (
      billToType.CASH_CCOnline === billToValue &&
      selectedPayment.CCI2P === billToCCValue &&
      isNullOrEmpty(selectedPaymentValue)
    ) {
      return { "addedCCResult.required": CheckoutValidationMessages["addedCCResult.required"] };
    }
    return null;
  };
};

const validateCreditCardAuthorization = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const paymentDetailsFormGroup =
      control as FormGroup<PaymentDetailsFormGroup>;
    const billToValue = paymentDetailsFormGroup.value.billTo;
    const billToCCValue = paymentDetailsFormGroup.value.billToCC;
    const selectedPaymentValue = paymentDetailsFormGroup.value.selectedPayment;
    if (
      billToType.CASH_CCOnline === billToValue &&
      billToCCValue !== selectedPaymentValue
    ) {
      return { "addedCCResult.pattern": CheckoutValidationMessages["addedCCResult.pattern"] };
    }
    return null;
  };
};

const validateCreditCardPaymentChoice = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const paymentDetailsFormGroup =
      control as FormGroup<PaymentDetailsFormGroup>;
    const orderType = (paymentDetailsFormGroup.root as FormGroup<CheckoutFormGroup>)?.value?.checkoutDetails?.transactionType;
    const billToValue = paymentDetailsFormGroup.value.billTo;
    const billToCCValue = paymentDetailsFormGroup.value.billToCC;
    const selectedPaymentValue = paymentDetailsFormGroup.value.selectedPayment;
    if (
      billToType.CASH_CCOnline === billToValue &&
      !billToCCValue &&
      isNullOrEmpty(selectedPaymentValue) &&
      orderType !== OrderType.Quote
    ) {
      return { "billToCC.required": CheckoutValidationMessages["billToCC.required"] };
    }
    return null;
  };
};

export const paymentDetailsValidators: ValidatorFn[] = [
  validateBillTo(),
  validateI2P(),
  validateI2PEmailFormat(),
  validateI2PEmailRequired(),
  validateI2PSmsRequired(),
  validateI2PSmsFormat(),
  validateCreditCard(),
  validateCreditCardAuthorization(),
  validateCreditCardPaymentChoice(),
];
