import { CommonModule } from '@angular/common';
import { } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbAccordionModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AutoCompleteComponent } from 'app/_components/autocomplete/autocomplete.component';
import { BranchMultiSelectComponent } from 'app/_components/branch-multiselect/branch-multiselect';
import { FiltersComponent } from 'app/_components/filters/filters.component';
import { InviteToPayComponent } from 'app/_components/invite-to-pay/invite-to-pay.component';
import { MultiSelectComponent } from 'app/_components/multiselect/multiselect.component';
import { NotesComponent } from 'app/_components/notes/notes.component';
import { PartQuantityComponent } from 'app/_components/part-quantity/part-quantity.component';
import { SpinnerComponent } from 'app/_components/spinner/spinner.component';
import { AutofocusDirective } from 'directives/autofocus.directive';
import { DebounceInputDirective } from 'directives/debounce-input.directive';
import { DefaultImageDirective } from 'directives/default-image.directive';
import { DigitsOnlyDirective } from 'directives/digits-only.directive';
import { FloatOnlyDirective } from 'directives/float-only.directive';
import { NgAfterViewInitDirective } from 'directives/ng-after-view-init.directive';
import { NgLetDirective } from 'directives/ng-let.directive';
import { NoopDirective } from 'directives/noop.directive';
import { RequiredPropertyValidatorDirective } from 'directives/required-property.directive';
import { StickyPopoverDirective } from 'directives/sticky-popover.directive';
import { MinDateDirective } from 'helpers/min-date.validator';
import { ChipClipInputModalComponent } from 'modals/chip-clip-input-modal/chip-clip-input-modal.component';
import { CloseReasonModalComponent } from 'modals/close-reason-modal/close-reason-modal.component';
import { CreatePartModalComponent } from 'modals/create-part-modal/create-part-modal.component';
import { CustomerModalComponent } from 'modals/customer-modal/customer-modal.component';
import { CustomerNotesModalComponent } from 'modals/customer-notes-modal/customer-notes-modal.component';
import { FavouriteCustomersModalComponent } from 'modals/favourite-customers-modal/favourite-customers-modal.component';
import { FavouriteVendorsModalComponent } from 'modals/favourite-vendors-modal/favourite-vendors-modal.component';
import { LineItemNotesModalComponent } from 'modals/line-item-notes-modal/line-item-notes-modal.component';
import { MscPayersModalComponent } from 'modals/msc-payers-modal/msc-payers-modal.component';
import { NationalInventoryModalComponent } from 'modals/national-inventory-modal/national-inventory-modal.component';
import { OverrideBinLocationModalComponent } from 'modals/override-bin-location-modal/override-bin-location-modal.component';
import { PartBinLocationsModalComponent } from 'modals/part-bin-locations-modal/part-bin-locations-modal.component';
import { CreatePartsResultModalComponent } from 'modals/parts-created-modal/create-parts-result-modal.component';
import { BuyAgainPopoverMessageModalComponent } from 'modals/popover-message-modal/buyagain-popover-message-modal.component';
import { PriceOverrideModalComponent } from 'modals/price-override-modal/price-override-modal.component';
import { PriceVerifyModalComponent } from 'modals/price-verify-modal/price-verify-modal.component';
import { NavistarPunchoutModalComponent } from 'modals/punchout-modals/navistar-punchout-modal.component';
import { FindLoyaltyProgramAccountModalComponent } from 'modals/select-loyalty-accounts-modal/find-loyalty-program-account-modal/find-loyalty-program-account-modal.component';
import { SearchLoyaltyProgramAccountsModalComponent } from 'modals/select-loyalty-accounts-modal/search-loyalty-program-accounts-modal/search-loyalty-program-accounts-modal.component';
import { SelectLoyaltyAccountsModalComponent } from 'modals/select-loyalty-accounts-modal/select-loyalty-accounts-modal.component';
import { UnknownPartBuyoutModalComponent } from 'modals/unknown-part-buyout-modal/unknown-part-buyout-modal.component';
import { VendorsModalComponent } from 'modals/vendors-modal/vendors-modal.component';
import {
  CommaReplacePipe,
  CreditCardPipe,
  CustomerNumberPipe,
  EmailToUserNamePipe,
  JoinPipe,
  NotificationTimePipe,
  PostalCodePipe,
  SomePipe,
  TelephoneNumberPipe,
  TrimLeadingZerosPipe,
  TrimPipe,
  UniqueStorageTypePipe
} from 'pipes';
import { DateFormatPipe } from 'pipes/date-format-pipe';
import { FilterByKeyPipe } from 'pipes/filter-by-key-pipe';
import { EmailValidator } from 'validators/email.validator';
import { RequiredTrimmedValidator } from 'validators/required-trimmed.validator';
import { BranchComponent } from '../_components/branch/branch.component';
import { CartCardComponent } from '../_components/cart-card/cart-card.component';
import { CountDownComponent } from '../_components/count-down/count-down.component';
import { CouponPopoverComponent } from '../_components/coupon-popover/coupon-popover.component';
import { CustomerPanelComponent } from '../_components/customer-panel/customer-panel.component';
import { CustomerSelectComponent } from '../_components/customer-select/customer-select.component';
import { HeaderSearchComponent } from '../_components/header-search/header-search.component';
import { LoyaltyAccountsDisplayComponent } from '../_components/loyalty-accounts-display/loyalty-accounts-display.component';
import { LoyaltyAccountsComponent } from '../_components/loyalty-accounts/loyalty-accounts.component';
import { OpenCartComponent } from '../_components/open-cart/open-cart.component';
import { PaginationComponent } from '../_components/pagination/pagination.component';
import { VendorPanelComponent } from '../_components/vendor-panel/vendor-panel.component';

const sharedModules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  NgbModule,
  NgbAccordionModule
];

const sharedDeclarations = [
  // Components
  CloseReasonModalComponent,
  MscPayersModalComponent,
  BuyAgainPopoverMessageModalComponent,
  LineItemNotesModalComponent,
  PriceVerifyModalComponent,
  NavistarPunchoutModalComponent,
  NationalInventoryModalComponent,
  PartQuantityComponent,
  CreatePartModalComponent,
  CustomerModalComponent,
  CustomerNotesModalComponent,
  FavouriteVendorsModalComponent,
  FavouriteCustomersModalComponent,
  FindLoyaltyProgramAccountModalComponent,
  PriceOverrideModalComponent,
  SearchLoyaltyProgramAccountsModalComponent,
  SelectLoyaltyAccountsModalComponent,
  UnknownPartBuyoutModalComponent,
  CouponPopoverComponent,
  ChipClipInputModalComponent,
  VendorsModalComponent,
  BranchComponent,
  BranchMultiSelectComponent,
  CustomerPanelComponent,
  VendorPanelComponent,
  OpenCartComponent,
  CountDownComponent,
  HeaderSearchComponent,
  PaginationComponent,
  CustomerSelectComponent,
  CartCardComponent,
  LoyaltyAccountsComponent,
  LoyaltyAccountsDisplayComponent,
  CreatePartsResultModalComponent,
  PartBinLocationsModalComponent,
  OverrideBinLocationModalComponent,
  InviteToPayComponent,
  SpinnerComponent,
  FiltersComponent,
  MultiSelectComponent,
  NotesComponent,
  AutoCompleteComponent,

  // Directives
  NgAfterViewInitDirective,
  DefaultImageDirective,
  DigitsOnlyDirective,
  FloatOnlyDirective,
  NgLetDirective,
  NoopDirective,
  RequiredPropertyValidatorDirective,
  StickyPopoverDirective,
  DebounceInputDirective,
  MinDateDirective,
  AutofocusDirective,

  // Pipes
  CustomerNumberPipe,
  JoinPipe,
  NotificationTimePipe,
  PostalCodePipe,
  SomePipe,
  TelephoneNumberPipe,
  TrimPipe,
  UniqueStorageTypePipe,
  CommaReplacePipe,
  TrimLeadingZerosPipe,
  EmailToUserNamePipe,
  DateFormatPipe,
  CreditCardPipe,
  FilterByKeyPipe,

  // Validators
  RequiredTrimmedValidator,
  EmailValidator
];


@NgModule({
  imports: [
    sharedModules
  ],
  declarations: [
    sharedDeclarations
  ],
  exports: [
    sharedModules,
    sharedDeclarations
  ]
})
export class SharedModule {
}
