export function isCashCustomer(customerNumber: string) {
  return ['200000', '200001', '0000200000'].includes(customerNumber);
}

export function isDefaultCustomerName(name: string): boolean {
  return name?.toUpperCase() === "RUSH CASH CUSTOMER";
}

export function isDefaultAddress(address: string): boolean {
  return address?.toUpperCase() === "555 I 35";
}
