import { InjectionToken } from "@angular/core";
import { environment } from "environments/environment";
import { UserManagerSettings } from "oidc-client";
import { LoyaltyProgram } from "entities/loyalty-account";

export const AUTH_SCOPES: string[] = [
  `https://${environment.openIdConnect.b2c.tenant}/${environment.APP_ID}/read`,
  `https://${environment.openIdConnect.b2c.tenant}/${environment.APP_ID}/write`,
];

export const AUTH_AUDIENCES = [{
  domain: environment.CORE_API_URL,
  scopes: AUTH_SCOPES
}];

export const RUSHCARE_URL: string =  environment.RUSHCARE_URL;

export const VIN_PARTS_SEARCH_URL: string =  environment.VIN_PARTS_SEARCH_URL;

export const AUTH_SIGNIN_POLICY: string = environment.openIdConnect.b2c.policy;

export const AUTH_PASSWORDRESET_POLICY: string = environment.openIdConnect.b2c.passwordResetPolicy;

export const AUTH_PROFILEEDIT_POLICY: string = environment.openIdConnect.b2c.profileEditPolicy;

export function AUTH_SETTINGS(): UserManagerSettings {
  return {
    authority: `https://${environment.openIdConnect.b2c.domain}/tfp/${environment.openIdConnect.b2c.tenant}/${environment.openIdConnect.b2c.policy}/v2.0`,
    client_id: environment.openIdConnect.clientId,
    redirect_uri: `${window.location.origin}/assets/signin_redirect.html`,
    silent_redirect_uri: `${window.location.origin}/assets/signin_silent.html`,
    post_logout_redirect_uri: `${window.location.origin}/assets/logout.html?logoutUrlsJsonUrl=${environment.openIdConnect.logoutUrlsJsonUrl}`,
    loadUserInfo: false
  };
}

export const APPLICATION_NAME = new InjectionToken<string>(
  'APPLICATION_NAME',
  {providedIn: "root", factory: () => "RushCare Parts Connect"}
);

export const IMAGE_BASE_URL = new InjectionToken<string>(
  'IMAGE_BASE_URL',
  {providedIn: "root", factory: () => "https://cdn.partsconnect.rushcare.com/images/"}
);

export const DEFAULT_IMAGE_URL = new InjectionToken<string>(
  'DEFAULT_IMAGE_URL',
  {providedIn: "root", factory: () => "assets/img/DefaultImage.JPG"}
);

export const REGEX_PHONE_PATTERN = new InjectionToken<RegExp>(
  "REGEX_PHONE_PATTERN",
  // Matches the following US numbers
  // 123-456-7890    //(123)-456-7890    //1234567890    //123 456 7890    //123.456.7890    //+91 (123)-456-7890
  // If you want a match on US numbers use  /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/
  { providedIn: 'root', factory: () => new RegExp(/^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/) }
);

export const EULA_VERSION = new InjectionToken<string>(
  "EULA_VERSION",
  {providedIn: 'root', factory: () => "2.0"}
);

export const EULA_MODIFIED_DATE = new InjectionToken<string>(
  "EULA_MODIFIED_DATE",
  {providedIn: 'root', factory: () => "3/18/2019"}
);

export const APPLICATION_INSIGHTS_KEY_TOKEN = new InjectionToken<string>(
  "APPLICATION_INSIGHTS_KEY",
  { providedIn: 'root', factory: () => environment.ApplicationInsights_Key }
);

export const NEW_USER_REDIRECT_URL_TOKEN = new InjectionToken<string>(
  "NEW_USER_REDIRECT_URL",
  { providedIn: 'root', factory: () => environment.NewUserRedirectUrl }
);

export const PUNCHOUT_NAVISTAR_URL_TOKEN = new InjectionToken<string>(
  "PUNCHOUT_NAVISTAR_URL",
  { providedIn: 'root', factory: () => environment.punchout.navistarUrl }
);

export const RUSH_IDENTITY_PROVIDER_TOKEN = new InjectionToken<string>(
  "RUSH_IDENTITY_PROVIDER",
  { providedIn: 'root', factory: () => "rush-enterprises.com" }
);

export const CORE_API_URL = new InjectionToken<string>(
  "CORE_API_URL",
  { providedIn: 'root', factory: () => environment.CORE_API_URL }
);

export const WEB_URL = new InjectionToken<string>(
  "WEB_URL",
  { providedIn: 'root', factory: () => environment.WEB_URL }
);

export const NOTIFICATION_TIME_INTERVAL_IN_MIN = new InjectionToken<number>(
  "NOTIFICATION_TIME_INTERVAL_IN_MIN",
  { providedIn: 'root', factory: () => environment.notificationTimeIntervalInMin }
);

export const LOYALTY_PROGRAMS = new InjectionToken<LoyaltyProgram[]>(
  "LOYALTY_PROGRAMS",
  { providedIn: 'root', factory: () => ['peterbilt'] }
);

export const REGEX_USA_POSTAL_PATTERN = new InjectionToken<RegExp>(
  "REGEX_USA_POSTAL_PATTERN",
  { providedIn: 'root', factory: () => new RegExp(/^\d{5}(?:-?\d{4})?$/) }
);

export const REGEX_CANADA_POSTAL_PATTERN = new InjectionToken<RegExp>(
  "REGEX_CANADA_POSTAL_PATTERN",
  { providedIn: 'root', factory: () => new RegExp(/[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ] ?[0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]/) }
);

export const UNIT_NUMBER_PATTERN = new InjectionToken<RegExp>(
  "REGEX_UNIT_NUMBER_PATTERN",
  { providedIn: 'root', factory: () => new RegExp(/^[a-zA-Z0-9\s]+$/) }
);