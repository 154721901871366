<button id="FavouriteCustomersModal_Button_Close" type="button" class="close" data-dismiss="modal" aria-label="Close" tabindex="-1" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
</button>
<div class="modal-header">
    <h4 class="modal-title">Favorites</h4>
    <div class="input-group w-33">
        <input id="FavouriteCustomersModal_Text_SearchFavourites" type="text" #searchFavouriteId class="form-control customer-search-textbox" [(ngModel)]=filterKey (keyup)="filter()" placeholder="Search Your Favorites...">
          <span class="input-group-text">
            <i class="fas fa-search" aria-hidden="true"></i>
          </span>
    </div>
</div>
<div id="FavouriteCustomersModal_Div_IsLoadingFavouriteCustomers" class="modal-body" *ngIf="!favouriteCustomers">
    <div>Loading favorite customer(s) ...</div>
</div>
<div id="FavouriteCustomersModal_Div_HasLoadedFavouriteCustomers" class="modal-body" *ngIf="favouriteCustomers">
    <div id="FavouriteCustomersModal_Div_HasNoFavouriteCustomers" *ngIf="!favouriteCustomers.length">
        <h3>No records found.</h3>
    </div>
    <div id="FavouriteCustomersModal_Div_HasFavouriteCustomers" *ngIf="favouriteCustomers.length" class="customer-table">
        <table class="table mb-1">
            <thead>
                <tr>
                    <th></th>
                    <th>
                        <a id="FavouriteCustomersModal_A_SortByName" role="button" href="javascript:void(0)" (click)="sortDataBy('customerName');">
                            Name
                            <span [hidden]="sortBy != 'customerName'" class="fas" [ngClass]="{'fa-caret-up': sortBy == 'customerName' && sortAsc, 'fa-caret-down': sortBy == 'customerName' && !sortAsc}"> </span>
                        </a>
                    </th>
                    <th>
                        <a id="FavouriteCustomersModal_A_SortByAddress" role="button" href="javascript:void(0)" (click)="sortDataBy('city');">
                            Address
                            <span [hidden]="sortBy != 'city'" class="fas" [ngClass]="{'fa-caret-up': sortBy == 'city'&& sortAsc, 'fa-caret-down': sortBy == 'city' && !sortAsc}"> </span>
                        </a>
                    </th>
                    <th style="white-space: nowrap">
                        <a id="FavouriteCustomersModal_A_SortBySalesmanNumber" role="button" href="javascript:void(0)" (click)="sortDataBy('accountManager');">
                            Salesman#
                            <span [hidden]="sortBy != 'accountManager'" class="fas" [ngClass]="{'fa-caret-up': sortBy == 'accountManager'&& sortAsc, 'fa-caret-down': sortBy == 'accountManager' && !sortAsc}"> </span>
                        </a>
                    </th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr id="FavouriteCustomersModal_Customer_{{i}}" *ngFor="let favourite of pagedFavouriteCustomers; let i = index" [ngClass]="{'disabledresult':favourite.favourPayerNotValidInBranch}"  style="cursor:pointer" (click)="onSelectFavouriteCustomer(favourite.customerId)">
                    <td>
                        <div title="Payer is not valid for this branch" *ngIf="favourite.favourPayerNotValidInBranch">
                            <i id="FavouriteCustomersModal_I_PayerNotValidIcon_{{i}}" class="fas fa-exclamation-triangle text-warning" aria-hidden="true"></i>
                        </div>
                    </td>
                    <td>
                        <div id="FavouriteCustomersModal_Div_CustomerName_{{i}}" *ngIf="favourite.customerName | trim">{{favourite.customerName}}</div>
                        <div id="FavouriteCustomersModal_Div_CustomerName2_{{i}}" *ngIf="favourite.customerName2 | trim">{{favourite.customerName2}}</div>
                        <div id="FavouriteCustomersModal_Div_CustomerNumber_{{i}}" *ngIf="favourite.customerNumber | trim">{{favourite.customerNumber | customerNumber}}</div>
                    </td>
                    <td>
                        <div id="FavouriteCustomersModal_Div_CustomerStreetAddress_{{i}}" *ngIf="favourite.streetAddress | trim">{{favourite.streetAddress}}</div>
                        <div id="FavouriteCustomersModal_Div_CustomerCity_{{i}}" *ngIf="(favourite.city | trim) || (favourite.state | trim) || (favourite.postalCode | trim)">{{[([favourite.city, favourite.state] | join:', '), favourite.postalCode] | join:' '}}</div>
                        <div id="FavouriteCustomersModal_Div_CustomerPhoneNumber_{{i}}" *ngIf="favourite.phoneNumber | trim">{{favourite.phoneNumber}}</div>
                    </td>
                    <td>
                        <div id="FavouriteCustomersModal_Div_AccountManagerName_{{i}}" *ngIf="favourite.accountManagerName | trim">{{favourite.accountManagerName +" ("+favourite.accountManager+")" }}</div>
                    </td>
                    <td class="text-end">
                        <a id="FavouriteCustomersModal_A_RemoveFavouriteCustomer_{{i}}" class="text-danger" role="button" href="javascript:void(0)" (click)="removeFavourites(favourite); $event.stopPropagation()">
                            <span aria-hidden="true" class="far fa-trash-alt fa-lg"></span>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div>Note : The symbol (<i class="fas fa-exclamation-triangle text-warning" aria-hidden="true"></i>) represents that payer number is invalid in this branch.</div>
</div>
<div class="modal-footer">
    <nav id="FavouriteCustomersModal_Nav_Navigation" class="d-flex justify-content-end align-items-center" aria-label="Page navigation" *ngIf="pager.pages && pager.pages.length">
        <div>
            <span class="pagination-result">Showing {{pager.startIndex + 1}} - {{pager.endIndex + 1}} of {{pager.totalItems}} Results</span>
        </div>
        <div>
            <ul class="pagination ">
                <li class="page-item" [ngClass]="{disabled:pager.currentPage == 1}">
                    <a id="FavouriteCustomersModal_A_FirstPage" class="page-link" aria-label="First" (click)="setPage(1)"><span aria-hidden="true">&laquo;</span></a>
                </li>
                <li class="page-item" [ngClass]="{disabled:pager.currentPage == 1}">
                    <a id="FavouriteCustomersModal_A_PreviousPage" class="page-link" aria-label="Previous" (click)="setPage(pager.currentPage - 1)"><span aria-hidden="true">&lsaquo;</span></a>
                </li>
                <li *ngFor="let page of pager.pages" class="page-item" [ngClass]="{active:pager.currentPage == page}">
                    <a id="FavouriteCustomersModal_A_CurrentPage" class="page-link" (click)="setPage(page)">{{page}}</a>
                </li>
                <li class="page-item" [ngClass]="{disabled:pager.currentPage == pager.totalPages}">
                    <a id="FavouriteCustomersModal_A_NextPage" class="page-link" aria-label="Next" (click)="setPage(pager.currentPage + 1)"><span aria-hidden="true">&rsaquo;</span></a>
                </li>
                <li class="page-item" [ngClass]="{disabled:pager.currentPage == pager.totalPages}">
                    <a id="FavouriteCustomersModal_A_LastPage" class="page-link" aria-label="Last" (click)="setPage(pager.totalPages)"><span aria-hidden="true">&raquo;</span></a>
                </li>
            </ul>
        </div>
    </nav>
</div>
