/* eslint-disable max-len */
import { Injectable } from "@angular/core";
import { BackCounterStateMock } from "store/back-counter/back-counter.state.mock";
import * as Entities from "store/back-counter/back-counter.entities";
import { Observable, Subject, firstValueFrom, map, of } from "rxjs";
import { BackCounterRequestDetails, RequestAttachment } from "store/back-counter/back-counter.entities";
import { HttpClient } from "@angular/common/http";
import { BackCounterConfig } from "store/back-counter/back-counter.config";
import { HttpResponse, HubConnection, HubConnectionBuilder, IHttpConnectionOptions } from "@microsoft/signalr";
import { environment } from "environments/environment";
import { AuthenticationService } from "app/_modules/authentication/authentication.service";
import { BackCounterNote } from "entities/notes";
import { Store } from "@ngrx/store";
import { AppState } from "store/app-state";
import * as BackCounterToolActions from 'store/back-counter/back-counter.actions';
import { SapShippingRatesResponse } from "entities/inbound-freight/shipping-rates-response";
import { SapInboundFreightRequest } from "entities/inbound-freight/inbound-freight-request";

@Injectable({
  providedIn: "root"
})
export class BackCounterDashboardService {

  private connection: HubConnection;
  public refreshGeneralRequestModalSubject: Subject<void> = new Subject();
  public refreshEventSubject: Subject<Entities.SapEventMessage> = new Subject();

  constructor(
    private store: Store<AppState>,
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) {  }


  public createConnection(): void {
    if (this.connection) {
      return;
    }

    const options: IHttpConnectionOptions = {
      withCredentials: true,
      accessTokenFactory: async () => await firstValueFrom(this.authenticationService.accessToken$)
    };

    const url = environment.API_URL + "api/backcounter";
    this.connection = new HubConnectionBuilder()
      .withUrl(url, options)
      .withAutomaticReconnect([2000, 5000, 10000, 30000, 60000, null])
      .build();

    this.connection.on("SapEventMessage", (message) => {
      this.store.dispatch(BackCounterToolActions.backCounterMessageReceived({eventInfo:message}));
    });

    this.connection.start().then(() => {
      console.log("signalr connection started.");
    }).catch((err) => {
      console.log(err);
    });
  }

  getRequests(payload: Entities.BackCounterRequestPayload): Observable<any> {
    const url = `api/backcounter/dashboard`;
    if (BackCounterConfig.mockData) {
      return of(BackCounterStateMock.requests);
    }
    return this.http.post<Entities.BackCounterDashboardPage>(url, payload);
  }

  updateRequest(requestId: string, payload: { priority?: string; assignedTo?: string }): Observable<any> {
    const url = `api/backcounter/request/${requestId}`;
    return this.http.post(url, payload);
  }

  updateGeneralRequest(requestId: string, payload: { priority?: string; assignedTo?: string }): Observable<any> {
    const url = `api/backcounter/generalrequest/${requestId}`;
    return this.http.post(url, payload);
  }

  updateRequestParts(requestId: string, payload: Entities.RequestPartItemsUnsavedChanges): Observable<any> {
    const url = `api/backcounter/request/${requestId}/item`;
    return this.http.post(url, payload);
  }

  getOverview(payload: Entities.BackCounterSummaryOfRequestsPayload): Observable<Entities.BackCounterRequestsOverview> {
    const url = `api/backcounter/dashboard/SummaryOfRequests`;
    return this.http.post<Entities.BackCounterRequestsOverview>(url, payload);
  }

  public getRequestDetails(orderNumber: string, withLock: boolean): Observable<BackCounterRequestDetails> {
    const url = `api/backcounter/request/${orderNumber}?withLock=${withLock}`;
    return this.http.get<BackCounterRequestDetails>(url).pipe(map((request) => ({
        ...request,
        ordertogetitem: request.ordertogetitem.map((item, i) => ({
          ...item, _id: `${item.jobNumber}_${i}`
        }))
    })));
  }

  public deleteRequestDetailsLock(orderNumber: string): Observable<any> {
    const url = `api/backcounter/request/lock/${orderNumber}`;
    return this.http.delete(url);
  }

  public getRequestAttachments(orderNumber: string){
    const url = `api/backcounter/request/${orderNumber}/photos`;
    return this.http.get<RequestAttachment[]>(url);
  }

  public getRequestAttachmentBase64(photoId: string, orderNumber: string){
    const url = `api/backcounter/request/${orderNumber}/photos/file?photoId=${photoId}`;
    return this.http.get<{ fileData: string }>(url);
  }

  public getRequestNotes(orderNumber: string){
    const url = `api/backcounter/generalrequest/${orderNumber}`;
    return this.http.get<BackCounterNote[]>(url);
  }

  public addGeneralRequest(orderNumber: string, noteText: string, branchCode: string){
    const url = `api/backcounter/generalrequest`;
    return this.http.post<boolean>(url, {
      orderNumber,
      noteText,
      branchCode
    });
  }

  public addNoteToGeneralRequest(orderNumber: string, noteText: string){
    const url = `api/backcounter/generalrequest/${orderNumber}`;
    return this.http.post<boolean>(url, {
      noteText
    });
  }
  public completeGeneralRequest(orderNumber: string){
    const url = `api/backcounter/generalrequest/${orderNumber}?markAsClosed=true`;
    return this.http.post<boolean>(url, {
    });
  }

  public completeRequest(orderNumber: string): Observable<any> {
    const url = `api/backcounter/request/${orderNumber}/complete`;
    return this.http.post<Entities.BackCounterDashboardPage>(url, {});
  }

  public getFreight(orderNumber: string, payload: SapInboundFreightRequest): Observable<SapShippingRatesResponse> {
    const url = `api/backcounter/request/${orderNumber}/getInboundFreight`;
    return this.http.post<SapShippingRatesResponse>(url, payload);
   }

}
