import { Component, OnInit, Inject, ChangeDetectionStrategy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { APPLICATION_NAME, LOYALTY_PROGRAMS } from 'app/app.constants';
import { Branch } from 'entities/branch';
import { Customer } from "entities/customer";
import { Permission } from 'entities/enums';
import { LoyaltyProgram, LoyaltyAccount } from 'entities/loyalty-account';
import { AuthorizationService } from 'services/authorization.service';
import { CommonDataService } from 'services/common-data.service';
import { ConfigurationService } from 'services/configuration.service';
import { AppState } from 'store/app-state';
import * as HomeActions from "store/home/home-page.actions";
import * as LoyaltyActions from 'store/loyalty/loyalty.actions';
import * as LoyaltySelectors from 'store/loyalty/loyalty.selectors';
import * as FeatureFlagSelectors from 'store/feature-flags/feature-flags.selectors';
import * as BranchSelectors from 'store/branch/branch.selectors';
import * as BranchActions from 'store/branch/branch.actions';
import * as CustomerSelectors from 'store/customer/customer.selectors';
import * as CustomerActions from 'store/customer/customer.actions';
import * as ConfigurationSelectors from 'store/configuration/configuration.selectors';
import { LoyaltyStateAccount } from 'store/loyalty/loyalty.state';

@Component({
  changeDetection: ChangeDetectionStrategy.Default,
  selector: 'home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
  permission = Permission;
  public branch$: Observable<Branch> = this.store.select(BranchSelectors.selectedBranch);
  public hasLoyaltyProgram$ = this.branch$
    .pipe(
      map((branch) => this.loyaltyPrograms.some((loyaltyProgram) => branch.brands.includes(loyaltyProgram)))
    );
  public customer$: Observable<Customer> = this.store.select(CustomerSelectors.selectedCustomer);
  public loyaltyAccounts$: Observable<LoyaltyStateAccount> = this.store.select(LoyaltySelectors.loyaltyAccount);
  public defaultCustomer$: Observable<Customer> = this.configurationService.customer$;
  public isDefaultCustomer$: Observable<boolean> = this.store.select(CustomerSelectors.isDefaultCustomer);
  public isInternal$: Observable<boolean> = this.store.select(ConfigurationSelectors.isInternal);
  public loyaltyEnabled$: Observable<boolean> = this.store.select(FeatureFlagSelectors.isFeatureActive('Loyalty'));
  public featuredProductsEnabled$: Observable<boolean> = this.store.select(FeatureFlagSelectors.isFeatureActive('FeaturedProducts.Home'));

  constructor(
    private store: Store<AppState>,
    public commonDataService: CommonDataService,
    private configurationService: ConfigurationService,
    public authorizationService: AuthorizationService,
    @Inject(LOYALTY_PROGRAMS) private loyaltyPrograms: LoyaltyProgram[],
    @Inject(APPLICATION_NAME) appName: string,
    title: Title
  ) {
    title.setTitle(`${appName} - Home`);
  }

  ngOnInit() {
    this.store.dispatch(HomeActions.loaded());
  }

  onCustomerSelect(customer: Customer) {
    this.store.dispatch(CustomerActions.selectCustomer({ customer }));
  }

  onLoyaltyAccountsChange(loyaltyAccount: LoyaltyAccount) {
    this.store.dispatch(LoyaltyActions.changeLoyaltyAccounts({ loyaltyAccount }));
  }

  onBranchSelect(branch: Branch) {
    this.store.dispatch(BranchActions.selectBranch({ branch }));
  }
}
