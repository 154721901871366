import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, Subject, Subscription, merge } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { Configuration } from 'entities/configuration';
import { Branch } from 'entities/branch';
import { Customer } from 'entities/customer';
import { User } from 'entities/user';
import { distinct } from 'helpers';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService implements OnDestroy {
  private loadConfigurationSubject: Subject<void> = new ReplaySubject(1);

  public configuration$: Observable<Configuration> = this.loadConfigurationSubject
    .pipe(
      switchMap(() => this.http.get<Configuration>('api/clientConfiguration')),
      shareReplay(1)
    );

  public branch$: Observable<Branch> = this.configuration$
    .pipe(
      map((configuration) => configuration.branch)
    );

  public customer$: Observable<Customer> = this.configuration$
    .pipe(
      map((configuration) => configuration.customer)
    );

  public user$: Observable<User> = this.configuration$
    .pipe(
      map((configuration) => configuration.user)
    );

  /** @deprecated use {@link allRoles$} instead; this value is not guaranteed to be populated at any given time */
  public allRoles: string[] = [];
  public allRoles$: Observable<string[]> = this.configuration$
    .pipe(
      map((configuration) => configuration.allRoles),
      tap((allRoles) => {
        this.allRoles = allRoles;
      }),
      shareReplay(1)
    );

  /** @deprecated use {@link allPermissions$} instead; this value is not guaranteed to be populated at any given time */
  public allPermissions: string[] = [];
  public allPermissions$: Observable<string[]> = this.configuration$
    .pipe(
      map((configuration) => {
        let allPermissions: string[] = configuration.globalPermissions;

        for (const entityCode in configuration.entityPermissions) {
          if (configuration.entityPermissions.hasOwnProperty(entityCode)) {
            allPermissions = allPermissions.concat(configuration.entityPermissions[entityCode]);
          }
        }

        // Get distinct values
        allPermissions = distinct(allPermissions);

        return allPermissions
      }),
      tap((allPermissions) => {
        this.allPermissions = allPermissions;
      }),
      shareReplay(1)
    );

  public globalPermissions$: Observable<string[]> = this.configuration$
    .pipe(
      map((configuration) => configuration.globalPermissions)
    );
  public entityPermissions$: Observable<{ [entityCode: string]: string[] }> = this.configuration$
    .pipe(
      map((configuration) => configuration.entityPermissions)
    );

  // Subscribe to ensure synchronous properties are written to
  private subscription: Subscription = merge(this.allRoles$, this.allPermissions$)
    .subscribe();

  constructor(private http: HttpClient) { }

  initialize() {
    this.loadConfigurationSubject.next();
  }

  refresh() {
    this.loadConfigurationSubject.next();
  }

  ngOnDestroy() {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  getConfiguration() {
    return this.http.get<Configuration>('api/clientConfiguration');
  }
}
