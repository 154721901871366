
<div class="modal-header">
  <div class="title-of-box-hold w-100">
    <h5 class="strong">General Request</h5>
  </div>
</div>


  <div class="modal-body">
    <div class="form-group">
        <div class="mb-4">
          <div *ngIf="!updateGeneralRequest; else readOnly">
            <b class="pr-2">Order #:</b>
            <input id="general-request-order-number" placeholder="Order Number" name="orderNumber" type="text" digitsOnly [(ngModel)]="orderNumber" maxlength="10" required/>
          </div>
          <ng-template #readOnly>
            <div class="row">
              <b class="pr-2 col-2">Request #:</b><b class="col-6">{{orderNumber}}</b>
            </div>
            <div class="row">
              <b class="pr-2 col-2">Order #:</b><b class="col-6">{{refOrderNumber}}</b>
            </div>
            <div class="row">
              <b class="pr-2 col-2">Submitter:</b><b class="col-6">{{requestor}}</b>
            </div>
          </ng-template>
        </div>
        <div *ngIf="updateGeneralRequest" id="general-request-messages" class="p-2" #generalRequestMessagesContainer>
          <div class="w-100">
            <div class="w-100">
              <div class="card mb-4" *ngFor="let note of notes;" #generalRequestMessages>
                <div class="card-header d-flex justify-content-between p-3">
                  <div class="w-100" *ngIf="note.techName !== null || note.userId !== null; else pendingNote">
                    <div class="row">
                      <div class="col-6">
                        <p class="fw-bold mb-0">{{note.techName ? note.techName : note.userId}}</p>
                      </div>
                      <div class="col-6 right-text-align">
                        <p class="text-muted small mb-0">
                          <i class="far fa-clock"></i>
                          {{note.time}} <br>
                          <i class="far fa-calendar"></i>
                          {{note.date}}
                        </p>
                      </div>
                    </div>
                  </div>
                  <ng-template #pendingNote>
                    <p>Pending Note</p>
                    <div class="spinner-border" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </ng-template>

                </div>
                <div class="card-body">
                  <pre class="mb-0">
                    {{note.noteText}}
                  </pre>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form #addGeneralRequestForm="ngForm">
          <textarea placeholder="Enter Notes Here" class="form-control m-2" id="general-request-note" name="note" rows="3" [(ngModel)]="note" required  *ngIf="!isComplete" maxlength="10000"></textarea>
        </form>
      </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" (click)="dismissModal();">Cancel</button>
    <button id="general-request-submit" (click)="submit();" class="btn btn-primary" type="submit" [disabled]="!addGeneralRequestForm.valid || isComplete">Submit</button>
    <button id="general-request-complete" class="btn btn-secondary" [disabled]="!updateGeneralRequest || isComplete" (click)="complete();">Complete</button>
  </div>



