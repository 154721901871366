import { ShipToCustomer } from "./ship-to-customer";

export type CartSearch =
  | {
    customerNumber: string;
    branchCode: string;
  }
  | {
    cartId: string;
  };

export class Cart {
    cartId: string = '';
    cartName: string = '';
    userId: string = '';
    branchCode: string = '';
    customerNumber: string = '';
    deliveryType: string;
    pONumber: string = '';
    specialInstruction: string = '';
    deliveryPrice: number = 0;
    partNumber: string = '';
    description: string = '';
    yourPrice: number = 0;
    quantity: number = 0;
    corePrice: number = 0;
    corecorePartNumber: string = '';
    partId: string = '';
    listPrice: number = 0;
    vmrsCode: string = '';
    vmrsDescription: string = '';
    manufacturer: string = '';
    cateogory: string = '';
    adjustedPrice: number = 0;
    finalPrice: number = 0;
    extendedPrice: number = 0;
    priceOverrideReasonId: number = 0;
    overridePriceApproval: string = '';
    overridePriceUserId: string = '';
    isBuyout: boolean = false;
    coreOption: string = null;
    partBuyoutCoreOption: string = null;
    PartNumOnly: string = null;
    IsRTCDelivery: boolean = false;
    IsCustomerDelivery: boolean = false;
    IsFreight: boolean = false;
    trackingParameters: any = '';
    binLocation: string = '';
    IsSTO: boolean = false;
    IsHotFlag: boolean = false;
    HotFlagCode: string = '';
    vendorCode: string = '';
    VendorBranchCode: string = '';
    VendorName: string = '';
    QuantityAvailable: number = 0;
    unitNumber: string;
    isSpecialPricing: boolean = false;
    PONumber: string = "";
    SpecialInstruction: string = "";
    UnitNumber: string = "";
    isSuggestedPart: boolean = false;
    corePurchasePrice: number = 0;
    isPriceVerified: boolean = false;
    verifiedPrice: number = 0;
}

export class CouponData {
    CouponId: string;
    CouponCode: string;
    CustomerSegmentId: string;
}

export enum DeliveryType {
  Pickup,
  AdHoc,
  SelfPick,
  FcKnown,
  Delivery,
  ShipTo,
  ShipDirect
}

export enum DeliveryTypes {
  pickup = "pickup",
  adHoc = "adhoc",
  selfPick = "selfpick",
  fcKnown = "fcknown",
  delivery = "delivery",
  shipTo = "shipto",
  shipDirect = "shipdirect"
}

export interface CreateCart {
    branchCode: string;
    customerNumber: string;
    forceCreate: boolean;
    updateShipToRequest?: ShipToCustomer
}
