<button id="NationalInventoryModal_Button_Close" type="button" class="close" data-dismiss="modal" aria-label="Close" tabindex="-1" (click)="closeModal()">
  <span aria-hidden="true">&times;</span>
</button>
<div class="modal-header">
  <div class="container">
    <div class="row">
      <div class="col-sm">
        <h4>National Inventory Search</h4>
      </div>
      <div class="col-sm">
        <div class="input-group">
          <input id="NationalInventoryModal_Text_Search" type="text" class="form-control customer-search-textbox" aria-describedby="inputGroupSuccess2Status" placeholder="Search ..." [(ngModel)]="filterKey" (keyup)="filter()" />
            <span class="input-group-text">
              <i class="fas fa-search" aria-hidden="true"></i>
            </span>
        </div>
      </div>

    </div>
  </div>
</div>


<div class="modal-body"  *ngIf="!inventories">
  <div class="container">
    <div class="row">
        <div class="col-sm">
          <h5 id="NationalInventoryModal_H5_IsLoading" class="input-group inventory-search-icon">Loading national inventory...</h5>
        </div>
    </div>
  </div>
</div>

<div class="modal-body input-group inventory-search-icon"  *ngIf="inventories != null">
  <div class="container" *ngIf="isPartMissingFromSolr === false">
    <div class="row">
      <div class="col-md-6 ms-md-auto">
        <div *ngIf="inventories.length==0">
          <h5 id="NationalInventoryModal_H5_NoneAvailable">None available at any other branches.</h5>
        </div>
      </div>
    </div>
  </div>

  <div class="container"  *ngIf="inventories.length > 0 || isPartMissingFromSolr === true">
    <div class="row">
      <div class="col-md-12 ms-md-auto">
        <div>
          <div class="inventory-search-table">
            <table class="table">
              <thead>
              <tr>
                <th>
                  <a id="NationalInventoryModal_A_SortByBranch" role="button" class="hyperlink" (click)="sortDataBy('branchCode');">
                    Branch
                    <span [hidden]="sortBy != 'branchCode'" class="fas" [ngClass]="{'fa-caret-up': sortBy == 'branchCode' && sortAsc, 'fa-caret-down': sortBy == 'branchCode' && !sortAsc}">
                        </span>
                  </a>
                </th>
                <th>
                  <a id="NationalInventoryModal_A_SortByDistance" role="button" class="hyperlink" (click)="sortDataBy('milesFromSource');">
                    Distance
                    <span [hidden]="sortBy != 'milesFromSource'" class="fas" [ngClass]="{'fa-caret-up': sortBy == 'milesFromSource' && sortAsc, 'fa-caret-down': sortBy == 'milesFromSource' && !sortAsc}">
                        </span>
                  </a>
                </th>
                <th>
                  <a id="NationalInventoryModal_A_SortByInventory" role="button" class="hyperlink" (click)="sortDataBy('quantity');">
                    Inventory
                    <span [hidden]="sortBy != 'quantity'" class="fas" [ngClass]="{'fa-caret-up': sortBy == 'quantity' && sortAsc, 'fa-caret-down': sortBy == 'quantity' && !sortAsc}">
                        </span>
                  </a>
                </th>
                <th id="NationalInventoryModal_TH_Quantity" *ngIf="authorizationService.hasPermission(permission.AllowProcurementOptions)">
                  Quantity
                </th>
                <th id="NationalInventoryModal_TH_NonBreakableSpace" *ngIf="authorizationService.hasPermission(permission.AllowProcurementOptions)">
                  &nbsp;
                </th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let inventory of filteredInventories; let i = index">
                <!--| inventoryFilterPipe:inventoryFilter;-->
                <td id="NationalInventoryModal_TD_Branch_{{i}}" style="width:40%"><b>{{inventory.branchCode +" - "+ inventory.branchName}}</b></td>
                <td id="NationalInventoryModal_TD_Distance_{{i}}" style="width:15%"> {{inventory.milesFromSource}} Miles</td>
                <td id="NationalInventoryModal_TD_Quantity_{{i}}" style="width:15%">{{inventory.quantity}}</td>
                <td id="NationalInventoryModal_TD_ChangeQuantity_{{i}}" style="width:20%" *ngIf="authorizationService.hasPermission(permission.AllowProcurementOptions)">
                  <div class="input-group spinner">
                    <part-quantity       
                      inputName="partDetails_text_Quantity"
                      inputPlaceholder="Quantity"
                      inputAutoComplete="off"
                      [value]="inventory.partsQuantity"
                      (onChangeQuantity)="changeQuantity($event, inventory)"
                      idModifier="NationalInventoryModal"
                      [index]="i"                 
                      [disabled]="(cartIsLocked$ | async)"
                    ></part-quantity>
                  </div>
                </td>
                <td style="width:10%" *ngIf="authorizationService.hasPermission(permission.AllowProcurementOptions)">
                  <button id="NationalInventoryModal_Button_AddToCart_{{i}}" type="submit" style="white-space: nowrap;" class="btn btn-default yellow-btn" (click)="addToCart(inventory)"
                          [disabled]="(cartIsLocked$ | async)">Add to Cart</button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container" *ngIf="isPartMissingFromSolr === true" id="missing-solr-container">
    <div class="row">
      <div class="col-md-12 ms-md-auto">
        <h5 class="strong">Please contact RushCare Customer Support at 855-787-4223 to inquire about national inventory for this part.</h5>
      </div>
    </div>
  </div>

</div>
