import { createFeatureSelector, createSelector } from "@ngrx/store";
import { BackCounterState } from "./back-counter.state";

export const selectBackCounterFeature = createFeatureSelector<BackCounterState>('backCounter');

export const selectBackCounterRequestsLoading = createSelector(
  selectBackCounterFeature,
  (state: BackCounterState) => state.loading
);

export const selectBackCounterRequests = createSelector(
  selectBackCounterFeature,
  (state: BackCounterState) => state.requests
);

export const selectBackCounterTotalRequestCount = createSelector(
  selectBackCounterFeature,
  (state: BackCounterState) => state.totalRequestCount
);

export const selectBackCounterOpenRequests = createSelector(
  selectBackCounterFeature,
  (state: BackCounterState) => state.openRequests
);

export const selectRequestPriceVerifications = createSelector(
  selectBackCounterFeature,
  (state: BackCounterState) => state.priceVerifyItems
);

export const selectRequestDetails = (requestId: string) =>
  createSelector(selectBackCounterFeature,
  (state: BackCounterState) => state.openRequests.find(x => x.orderNumber === requestId )
);

export const selectRequestDetailsUnsavedChanges = (requestId: string) =>
  createSelector(selectBackCounterFeature,
  (state: BackCounterState) => state.unsavedChanges[requestId]
);

export const selectRequestDetailsLoading = createSelector(
  selectBackCounterFeature,
  (state: BackCounterState) => state.requestDetailsLoading
);

export const selectBackCounterIsConfirmationLoading = createSelector(
  selectBackCounterFeature,
  (state: BackCounterState) => state.isConfirmationLoading
);
