import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { BackCounterConfirmCompleteModalComponent } from "app/_components/back-counter-confirm-complete-modal/back-counter-confirm-complete-modal.component";
import { BackCounterGeneralRequestModalComponent } from "app/_components/back-counter-general-request-modal/back-counter-general-request-modal.component";
import { NotesComponent } from "app/_components/notes/notes.component";
import { PartSearchModalComponent } from "app/_components/part-search-modal/part-search-modal.component";
import { BackCounterNote } from "entities/notes";
import { MscPriceVerifyData } from "entities/price-verify/msc-price-verify-data";
import { MscPriceVerifyPart } from "entities/price-verify/msc-price-verify-part";
import { ToastType } from "entities/toast-type";
import { BctPriceVerifyModalComponent } from "modals/bct-price-verify-modal/bct-price-verify-modal.component";
import { Observable, Subject, of } from "rxjs";
import { catchError, filter, map, switchMap, takeUntil, tap, withLatestFrom } from "rxjs/operators";
import { BackCounterDashboardService } from "services/back-counter-dashboard.service";
import { CustomerService } from "services/customer.service";
import { DownloadService } from "services/download.service";
import { FeatureFlagService } from "services/feature-flag.service";
import { ModalService } from "services/modal.service";
import { PartService } from "services/part.service";
import { ToastService } from "services/toast.service";
import { VendorService } from "services/vendor.service";
import { AppState } from "store/app-state";
import * as BackCounterToolActions from 'store/back-counter/back-counter.actions';
import * as BackCounterToolSelectors from 'store/back-counter/back-counter.selectors';
import * as BranchSelectors from "store/branch/branch.selectors";
import * as CustomerSelectors from "store/customer/customer.selectors";
import * as FeatureFlagSelectors from "store/feature-flags/feature-flags.selectors";
import * as actions from "./back-counter.actions";
import { BackCounterConfig, ToastMessages } from "./back-counter.config";
import { BackCounterRequestDetails, RequestConnectionEvent, RequestNote, RequestOrderItem, SapEventMessage } from "./back-counter.entities";
import { mapCoreOption } from "./back-counter.reducer.util";
import { selectBackCounterOpenRequests } from "./back-counter.selectors";

@Injectable()
export class BackCounterEffects {

  openSearchModal$ = createEffect(() => this.action$.pipe(
    ofType(actions.addPartToRequest),
    withLatestFrom(
      this.store.select(CustomerSelectors.selectedCustomer),
      this.store.select(BranchSelectors.selectedBranch)),
    switchMap(([{orderNumber, jobNumber, mainPrItem, onSelectPart}, customer, branch]) =>
      this.modalService.open(
        PartSearchModalComponent,
        {branch, customer, initialSearchTerm: '', displayType: 'back-counter-tool', onSelectPart: (part) => {
          onSelectPart({
            orderNumber,
            jobNumber,
            orderQuantity: part.quantity,
            quantityAvailable: part.quantityAvailable,
            purchaseVendor: null,
            freight: "",
            mainPrItem,
            coreOption: mapCoreOption(part.coreOption),
            materialNumber: part.rushPartNumber,
            deliveryDate: "",
            itemCategory: "P002",
            orderUom: part.unitOfMeasure,
            corePrice: null,
            manualPrice: null,
            unsavedChanges: "ADD",
            itemTotal: part.listPrice
          });
          this.toastService.showToast(ToastMessages.ADD_PART_SUCCESS, ToastType.Success);
        }},
        {size: 'lg'}
      ))
  ), { dispatch: false });

  getVendorForPart$ = createEffect(() => this.action$.pipe(
    ofType(actions.getVendorsCode),
    withLatestFrom(this.store.select(BranchSelectors.selectedBranch)),
    switchMap(([action, branch]) =>
      this.vendorService.getVendorPrice(null,null,false, action.materialNumber, branch.code).pipe(
        map((result) => action.onComplete(Number(result.vendorNumber)))
      )
    )
  ), { dispatch: false });

  getRequests$ = createEffect(() => this.action$.pipe(
    ofType(actions.getBackCounterRequests),
    switchMap(({ payload }) => this.backCounterDashboardService.getRequests(payload)
      .pipe(
        map((response) =>
          response.ErrorType ?
            actions.getBackCounterRequestsFailed({ error: response }) :
            actions.getBackCounterRequestsSuccess({ requests: response })
        ),
        catchError(async (error) => actions.getBackCounterRequestsFailed({ error }))
      )
    )
  ));

  showErrorMessage$ = createEffect(() => this.action$.pipe(
    ofType(actions.getBackCounterRequestsFailed),
    tap((error) => {
      if( error && error?.error?.detail) {
        this.toastService.showToast(ToastMessages.ERROR_DETAILED_LOADINGREQUESTS(error.error.detail), ToastType.Error);
      }else{
        this.toastService.showToast(ToastMessages.ERROR_LOADINGREQUESTS, ToastType.Error);
      }
    })
  ), { dispatch: false });

  getRequestDetails$ = createEffect(() => this.action$.pipe(
    ofType(
      actions.openBackCounterRequest
    ),
    withLatestFrom(this.store.select(selectBackCounterOpenRequests)),
    filter(([{ orderNumber }, openRequests]) => this.checkMaximumActiveRequests(orderNumber, openRequests)),
    switchMap(([{ orderNumber}, openRequests]) => {
        const alreadyOpen = openRequests?.find( i => i.orderNumber === orderNumber && i.branch);
        if( alreadyOpen ) {
          return of(actions.getRequestDetailsSuccess({
            requestDetails: alreadyOpen,
            US129696Enabled: true
          }));
        }
      return this.backCounterDashboardService.getRequestDetails(orderNumber, false).pipe(
      map((requestDetails) => actions.getRequestDetailsSuccess({
        requestDetails,
        US129696Enabled: true
      })),
      catchError((error) => {
        if( error.message === "LOCKED"){
          this.toastService.showToast(ToastMessages.REQUEST_LOCKED(''), ToastType.Error);
        }
        return of(actions.getRequestDetailsFailed({ error }));
      })
    );
  })));

  getRequestDetailsToEdit$ = createEffect(() => this.action$.pipe(
    ofType(
      actions.editBackCounterRequest
    ),
    switchMap(({ orderNumber, inEditMode }) => this.backCounterDashboardService.getRequestDetails(orderNumber, inEditMode).pipe(
      map((requestDetails) => {
        if( !inEditMode){
          this.store.dispatch(actions.removeRequestLock({orderNumber}));
        }else{
          requestDetails.isLocked = true;
        }

        return actions.getRequestDetailsSuccess({
          requestDetails,
          // TODO: Remove this boolean
          US129696Enabled: true
        });
      }),
      catchError((error) => {
        if( error.title === "LOCKED"){
          this.toastService.showToast(ToastMessages.REQUEST_LOCKED(error.detail), ToastType.Error);
        }
        return of(actions.getRequestDetailsFailed({ error }));
      })
    ))
  ));

  unlockRequestDetails$ = createEffect(() => this.action$.pipe(
    ofType(
      actions.closeBackCounterRequest,
      actions.removeRequestLock
    ),
    switchMap((action) => {
      if(action.type === '[Back Counter] Close Request'){
        return this.backCounterDashboardService.deleteRequestDetailsLock(action.requestId);
      } else {
        this.backCounterDashboardService.deleteRequestDetailsLock(action.orderNumber);
      }
    })
  ), {dispatch: false});

  getRequestAttachments$ = createEffect(() => this.action$.pipe(
    ofType(actions.getRequestAttachments),
    switchMap(({ orderNumber }) => this.backCounterDashboardService.getRequestAttachments(orderNumber).pipe(
      map((attachments) => actions.getRequestAttachmentsSuccess({ attachments, orderNumber })),
      catchError((error) => of(actions.getRequestAttachmentsFailed({ error })))
    ))
  ));

  openAttchament$ = createEffect(() => this.action$.pipe(
    ofType(actions.openAttachment),
    switchMap(({orderNumber, photoId, mimetype, fileName}) => this.backCounterDashboardService.getRequestAttachmentBase64(photoId, orderNumber).pipe(
      map((file) => {
        this.dowloadService.downloadFileBase64(fileName.split('Item:')[1], mimetype, file.fileData);
        return of(actions.openAttachmentSuccess());
      }),
      catchError((error) => of(actions.openAttachmentFailed({error})))
    )),
  ), {dispatch: false});

  openGeneralRequestModal$ = createEffect(() => this.action$.pipe(
    ofType(actions.openGeneralRequestModal),
    switchMap(() => this.modalService.open(BackCounterGeneralRequestModalComponent).pipe(
      withLatestFrom(this.store.select(BranchSelectors.selectedBranch)),
          filter(([{orderNumber, note}, _]) =>
          orderNumber !== null && orderNumber !== "" && note !== null && note !== ""), // Validate output of modal
          switchMap(( [{orderNumber, note}, branch]) => this.backCounterDashboardService.addGeneralRequest(orderNumber, note, branch.code).pipe(
          map((response) => {
            this.toastService.showToast(ToastMessages.GENERAL_REQUEST_ADD_SUCCESS, ToastType.Success);
            return actions.addGeneralRequestSuccess();
          }),
          catchError((error) => of(actions.addGeneralRequestFailed({error}))),
        )),
      ))
  ));

  updateGeneralRequest$ = createEffect(() => this.action$.pipe(
    ofType(actions.updateGeneralRequest),
    switchMap(({orderNumber, refOrderNumber, requestor, isComplete}) =>
      this.backCounterDashboardService.getRequestNotes(orderNumber).pipe(
        this.convertNoteTypes(),
        switchMap((notes) => {
            const updateNoteSubj = new Subject<{orderNumber: string; note: string}>();
            const addNoteSubj = new Subject<{orderNumber: string; note: string}>();

            const modal = this.ngbModalService.open(BackCounterGeneralRequestModalComponent);
            modal.componentInstance.updateGeneralRequest = true;
            modal.componentInstance.orderNumber = orderNumber;
            modal.componentInstance.refOrderNumber = refOrderNumber;
            modal.componentInstance.requestor = requestor;
            modal.componentInstance.notes = notes;
            modal.componentInstance.updateNote = updateNoteSubj;
            modal.componentInstance.addNote = addNoteSubj;
            modal.componentInstance.isComplete = isComplete;

            const refreshSubj = this.backCounterDashboardService.refreshEventSubject.pipe(
              filter(x => x.requestId === orderNumber.padStart(10, '0') && x.type === RequestConnectionEvent.newNoteOnGeneralRequest),
              takeUntil(modal.result),
              switchMap(() => this.getRequestNotes(orderNumber).pipe(
                tap((refreshedNotes: RequestNote[]) => {
                  modal.componentInstance.notes = refreshedNotes;
                })
              ))
            ).subscribe();

            return updateNoteSubj.pipe(
              switchMap(({ note }) =>
                this.backCounterDashboardService
                  .addNoteToGeneralRequest(orderNumber, note)
                  .pipe(
                    map(() => {
                      this.toastService.showToast(
                        `Successfully updated order #${orderNumber}`,
                        ToastType.Success
                      );
                      addNoteSubj.next({orderNumber, note});

                      return actions.updateGeneralRequestSuccess({
                        success: true,
                      });
                    }),
                    catchError((error) => {
                      if( error?.detail ){
                        this.toastService.showToast(
                          `Error updating order #${orderNumber}: ${error.detail}`,
                          ToastType.Error
                        );
                      }else{
                        this.toastService.showToast(
                          `Error updating order #${orderNumber}`,
                          ToastType.Error
                        );
                      }
                      return of(actions.updateGeneralRequestFailed({ error }));
                    }
                    )

                  )
              )
            );
          }
        ),
        catchError((error) => of(actions.getRequestNotesFailed({error})))
  ))));

  updateRequest$ = createEffect(() => this.action$.pipe(
    ofType(actions.updateBackCounterRequest),
    withLatestFrom(
      this.store.select(FeatureFlagSelectors.isFeatureActive('ChangeControl.NotForRelease'))
    ),
    switchMap(([{orderNumber, onComplete, payload}, notForRelease]) =>
      of(actions.ignoredUpdateBackCounterRequest()))
  ));

  updateRequestInlineFreight$ = createEffect(() => this.action$.pipe(
    ofType(actions.updateBackCounterRequest),
    withLatestFrom(this.store.select(BackCounterToolSelectors.selectRequestPriceVerifications)),
    switchMap(([action, allPriceVerifyParts]) => {
      const payloadCopy = JSON.parse(JSON.stringify(action.payload)); // Get a  deep copy of the payload
      const writableItemsToUpdate: RequestOrderItem[] =  payloadCopy.itemsToUpdate;
      const priceVerifyParts = allPriceVerifyParts[action.orderNumber];
      writableItemsToUpdate.forEach((part) => {
        if (part.freightOverride && parseFloat(part.freightOverride) > -1) {
          part.freightInbound = parseFloat(part.freightOverride);
          part.manualFreightFlag = "X";
        }
        if(priceVerifyParts){
          const verifyPart = priceVerifyParts[part.materialNumber];
          if(verifyPart){
            part.priceVerifyComp = "X";
            part.priceVerifyRel = "X";
            part.manualPrice = verifyPart.unitPrice;
            part.manPrice = verifyPart.unitPrice.toString();
          }
        }
      });

      return this.backCounterDashboardService.updateRequestParts(action.orderNumber, payloadCopy).pipe(
        map((part) => {
          action.onComplete(true);
          this.toastService.showToast(ToastMessages.UPDATE_SUCCESS, ToastType.Success);
          return actions.updateBackCounterRequestSuccess({part});
        }),
        catchError((error) => {
          action.onComplete(false);
          if (error?.detail) {
            this.toastService.showToast(ToastMessages.UPDATE_DETAILED_FAILED(error.detail), ToastType.Error);
          } else {
            this.toastService.showToast(ToastMessages.UPDATE_FAILED, ToastType.Error);
          }
          return of(actions.updateBackCounterRequestFailed({error}));
        })
      );
    }
  )));

  handleErrors$ = createEffect(() => this.action$.pipe(
    ofType(actions.addGeneralRequestFailed),
    tap(({error}) => {
      this.toastService.showToast(error.detail, ToastType.Error);
    })
  ), {dispatch: false});

  backCounterMessageReceived$ = createEffect(() => this.action$.pipe(
    ofType(actions.backCounterMessageReceived),
    tap(({eventInfo} ) => {
      this.backCounterDashboardService.refreshEventSubject.next(eventInfo);
    })
  ), {dispatch: false});

  openRequestNotesModal$ = createEffect(() => this.action$.pipe(
    ofType(actions.openNotesModal),
    switchMap(({ config }) => this.modalService.open(NotesComponent, { config }))
  ), { dispatch: false });

  closeRequestNotesModal$ = createEffect(() => this.action$.pipe(
    ofType(actions.closeNotesModal),
    tap(({config} ) => {
      const simEventInfo: SapEventMessage = {
        type: config.type,
        requestId: config.id,
        noteId: ""
      };
      this.backCounterDashboardService.refreshEventSubject.next(simEventInfo);
    }),
  ), { dispatch: false });

  openConfirmCompleteModal$ = createEffect(() => this.action$.pipe(
    ofType(actions.openConfirmCompleteModal),
    switchMap(({ orderNumber, onCompleted }) => this.modalService.open(
      BackCounterConfirmCompleteModalComponent, { orderNumber },{ size: "sm", backdrop: "static", keyboard: false }
    ).pipe(
      switchMap(() => {
        this.store.dispatch(BackCounterToolActions.completeConfirmed({lockConfirm: true}));
        return this.backCounterDashboardService.completeRequest(orderNumber).pipe(
          tap((results) => {
            if(results?.ErrorType) {
              this.toastService.showToast(ToastMessages.DEFAULT_ERROR, ToastType.Error);
            } else {
              this.toastService.showToast(ToastMessages.REQUEST_COMPLETED, ToastType.Success);
              onCompleted();
            }
            this.store.dispatch(BackCounterToolActions.completeConfirmed({lockConfirm: false}));
          }),
          catchError((error) => {
            if (error?.detail) {
              this.toastService.showToast(ToastMessages.ERROR_DETAILED_COMPLETING_REQUEST(error.detail), ToastType.Error);
            } else {
              this.toastService.showToast(ToastMessages.DEFAULT_ERROR, ToastType.Error);
            }
            this.store.dispatch(BackCounterToolActions.completeConfirmed({lockConfirm: false}));
            return of(error);
          })
        );
      })
    ))
  ), { dispatch: false });

  completeGeneralRequest$ = createEffect(() => this.action$.pipe(
    ofType(actions.completeGeneralRequest),
    switchMap(({orderNumber}) => this.backCounterDashboardService.completeGeneralRequest(orderNumber).pipe(
      map((success) => {
        this.toastService.showToast(ToastMessages.GENERAL_REQUEST_COMPLETED, ToastType.Success);
        this.store.dispatch(BackCounterToolActions.backCounterMessageReceived({eventInfo:{
          noteId:'',
          type: RequestConnectionEvent.updatedRequest,
          requestId: orderNumber.padStart(10,'0')}}));
        return actions.completeGeneralRequestSuccess({success});
      }),
      catchError((error) => {
        this.toastService.showToast(ToastMessages.DEFAULT_ERROR, ToastType.Error);
        this.store.dispatch(actions.updateGeneralRequest({orderNumber, refOrderNumber: "", requestor: "", updateGeneralRequest: true, isComplete: false}));
        return of(actions.completeGeneralRequestFailed({error}));
      })
    ))
  ), {dispatch: false});

  openBCTPriceVerifyModal$ = createEffect(() => this.action$.pipe(
    ofType(BackCounterToolActions.openBCTPriceVerifyModal),
    switchMap((action) =>
      this.customerService.getMscPayers({IncludeShipTo: false, SalesOrganization: "1000", AccountGroups: ["Z001", "Z002"], BranchCode: action.branchCode, CustomerNumber: action.customerNumber}).pipe(
        map(({mscCardNumber}) =>  ({...action, mscCardNumber}))
      )
    ),
    filter(({mscCardNumber}) => mscCardNumber !== null && mscCardNumber !== undefined && mscCardNumber !== ""),
    switchMap(({branchCode, customerNumber, requestId, onVerificationSuccess, mscCardNumber}) =>
      this.modalService.open(BctPriceVerifyModalComponent, {mscCardNumber}).pipe(
        filter((mscCardNumber) => mscCardNumber !== null),
        withLatestFrom(
          this.store.select(BackCounterToolSelectors.selectRequestDetails(requestId))
        ),
        switchMap(([mscCardNumber, request]) =>{
          const mscPriceVerifyParts = request.ordertogetitem
            .filter(x => x.materialNumber !== "" && x.materialNumber !== null).map(x => ({
            partNumber: x.materialNumber,
            quantity: 1,
            unitPrice: x.itemTotal
          } as MscPriceVerifyPart));

          return this.partService.BCTMscPriceVerification({
            branchCode,
            customerNumber,
            mscCardNumber,
            mscPriceVerifyParts,
            payerAccountNumber: "M100000",
            salesOrganization: null
          } as MscPriceVerifyData).pipe(
            tap((priceVerifyParts) => {
              const toastMessages = priceVerifyParts.messages.map((x) => {
                return {message: x.message, type: ToastType[x.type]}
              });
              this.toastService.showMultilineToast(toastMessages);
              onVerificationSuccess(priceVerifyParts.priceVerifyItems.filter(x => x.isPriceVerified));
            }),
            map((priceVerifyParts) => BackCounterToolActions.BCTPriceVerifySuccess({priceVerifyParts, requestId})),
            catchError((error) => of(BackCounterToolActions.BCTPriceVerifyFailed({error})))
          )
        }
      ))
    )
  ));

  constructor(
    private readonly store: Store<AppState>,
    private readonly action$: Actions,
    private readonly backCounterDashboardService: BackCounterDashboardService,
    private readonly toastService: ToastService,
    private readonly modalService: ModalService,
    private readonly vendorService: VendorService,
    private readonly router: Router,
    private readonly ngbModalService: NgbModal,
    private readonly dowloadService: DownloadService,
    private readonly partService: PartService,
    private readonly featureFlagService: FeatureFlagService,
    private readonly customerService: CustomerService
  ) { }

  public checkMaximumActiveRequests(orderNumber: string, openRequests: BackCounterRequestDetails[]): boolean {
    const requestIsNotOpened = !!openRequests.find((r) => r.orderNumber === orderNumber);
    const allowed = requestIsNotOpened && openRequests.length <= BackCounterConfig.activeRequestsLimit;
    if (!allowed) {
      this.toastService.showToast(ToastMessages.ERROR_LIMIT, ToastType.Error);
      this.router.navigate([BackCounterConfig.routes.dashboard]);
    }
    return allowed;
  }

  getRequestNotes(orderNumber: string): Observable<RequestNote[]> {
    return this.backCounterDashboardService.getRequestNotes(orderNumber).pipe(
      this.convertNoteTypes()
    );
  }

  private convertNoteTypes() {
    return map((bctnotes: BackCounterNote[]) => bctnotes.map<RequestNote>((x): RequestNote => {
      const theDate = new Date( Date.parse(x.utcTimestamp));
      return {
        userId: x.userid,
        techName: x.techname,
        techNumber: x.techno,
        noteText: x.notetext,
        date: x.utcTimestamp? theDate.toLocaleDateString() : x.wdate,
        time: x.utcTimestamp? theDate.toLocaleTimeString() : x.wtime
      };
  }));
  }

}
